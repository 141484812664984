import { DataArray } from '@nef/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTradeAnalysis } from '../../store/tradesSlice';
import { formatTradePrice } from '../../utils/currency';

const numberFormatter = new Intl.NumberFormat(
    'en-US',
    {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })
    
const TradesAnalyis = () => {

    const tradeAnalysis = useSelector(getTradeAnalysis);

    return (

        <DataArray
            data={[
                { label: "High", value: formatTradePrice(tradeAnalysis?.high, "-") },
                { label: "Low", value: formatTradePrice(tradeAnalysis?.low, "-") },
                { label: "VWAP", value: formatTradePrice(tradeAnalysis?.vwap, "-") },
                { label: "Trades", value: numberFormatter.format(tradeAnalysis.totalTrades) },
                { label: "Shares", value: numberFormatter.format(tradeAnalysis.totalShares) }
            ]}
        />
    );
}

export default TradesAnalyis;