import React from 'react';
import { useSelector } from 'react-redux';
import LogInPage from '../../layout/LogInPage';
import { getSession } from '../../store/session';

const LoginGate = (props: any) => {
    const session = useSelector(getSession);

    if (session.isLoggedIn) {
        return props.children;
    } else {
        return <LogInPage />;
    }
}

export default LoginGate;