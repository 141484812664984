import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Replay, getReplays, openReplay, selectReplay, toggleReplaySelected, getOpenReplay, getOpenReplayIsLoading } from '../../store/requestsSlice';
import { EmptyState, ListItem, UncontrolledTooltip } from '@nef/core';
import './ReplayList.css';
import ReplayListButtons from '../replay-list-buttons';
import { DateTime } from 'luxon';

function getIcon(r: Replay, isLoading: boolean) {
    if (isLoading) {
        return 'fa-spinner fa-spin';
    } else {
        switch (r.status) {
            case 'fetching': return 'fa-spinner fa-spin';
            case 'error': return 'fa-times-circle';
            case 'success': return 'fa-check';
            default: return '';
        }
    }
}

const ReplayList: React.FC = () => {
    const dispatch = useDispatch();
    const replays = useSelector(getReplays);
    const currentReplay = useSelector(getOpenReplay);
    const loading = useSelector(getOpenReplayIsLoading);

    const handleRowSelected = (id: string, e: React.MouseEvent<HTMLElement, MouseEvent>) => {

        if (e.ctrlKey) {
            dispatch(toggleReplaySelected(id));
        } else {
            dispatch(selectReplay(id));
        }
    }

    const handleRowDoubleClick = (replay: Replay) => {
        if (replay.status === 'success') {
            dispatch(openReplay(replay));
        }
    }

    const isLoading = (r: Replay) => currentReplay !== null && currentReplay.id === r.id && loading;

    const items = replays.map(r =>
        <ListItem
            className="replay-list-item"
            key={r.id}
            icon={{ type: "icon", iconClassName: getIcon(r, isLoading(r)) }}
            title={`${r.details.symbol} - ${r.details.start.toISODate()}`}
            subtitle={`${r.details.start.toLocaleString(DateTime.TIME_24_SIMPLE)} - ${r.details.end.toLocaleString(DateTime.TIME_24_SIMPLE)}`}
            pointer={true}
            onClick={e => handleRowSelected(r.id, e)}
            onDoubleClick={() => handleRowDoubleClick(r)}
            active={r.selected}
        >
            <div className="replay-icon-tooltip">
                <UncontrolledTooltip
                    placement="right"
                    title={<span>This replay {r.details.hasQuotes ? 'includes' : 'does not include'} quotes</span>}>
                    <div className={`replay-icon replay-icon--quotes ${r.details.hasQuotes ? 'replay-icon--active' : ''}`}>
                        Q
                    </div>
                </UncontrolledTooltip>
            </div>
            <div className="replay-icon-tooltip">
                <UncontrolledTooltip
                    placement="right"
                    title={<span>This replay {r.details.hasTrades ? 'includes' : 'does not include'} trades</span>}>
                    <div className={`replay-icon replay-icon--trades ${r.details.hasTrades ? 'replay-icon--active' : ''}`}>
                        T
                    </div>
                </UncontrolledTooltip>
            </div>
        </ListItem>
    );

    if (items.length > 0) {
        return (
            <>
                <ol className="replay-list">{items}</ol>
                <ReplayListButtons />
            </>
        );
    } else {
        return (
            <EmptyState
                titleSize={3}
                icon="documents"
                emptyStateTitle="No replays downloaded"
            >
                Download a replay to view trades and/or quotes.
            </EmptyState>
        )
    }


};


export default ReplayList;