import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStore } from ".";

const MIN_ZOOM_MILLISECONDS = 10;

export enum FilterMode {
    All = "ALL",
    Trades = "TRADES",
    Quotes = "QUOTES"
};

interface ViewsState {
    views: TimeRange[],
    currentTime: number,
    analysisRegion: TimeRange | null,
    filter: {
        mode: FilterMode
    },
    includedMarketCenters: string[]
}

export interface TimeRange {
    startTime: number,
    endTime: number
}

export const timeRangeContains = (outer: TimeRange, inner: TimeRange) => outer.startTime <= inner.startTime && outer.endTime >= inner.endTime;
export const timeRangesAreEqual = (left: TimeRange, right: TimeRange) => left.startTime === right.startTime && left.endTime === right.endTime;
export const intersectTimeRanges : (left : TimeRange, right : TimeRange) => TimeRange = (left, right) => (
    {
        startTime: Math.max(left.startTime, right.startTime),
        endTime: Math.min(left.endTime, right.endTime)
    }
);

const initialState: ViewsState = {
    views: [{startTime: 7*60*60*1000, endTime: 7*60*60*1000 + 5*60*1000}],
    currentTime: 7*60*60*1000,
    analysisRegion: null,
    filter: { mode: FilterMode.All },
    includedMarketCenters: []
};

interface SetCurrentTimeAction {
    time: number
}

interface PanAction {
    newStartTime: number
}

interface SetAnalysisRegionAction {
    region: TimeRange
}

const setCurrentTimeReducer = (state: ViewsState, action: PayloadAction<SetCurrentTimeAction>) => { state.currentTime = action.payload.time };

const forwardsReducer = (state: ViewsState, action: PayloadAction<number>) => {
    const currentView = state.views[state.views.length - 1];
    const length = currentView.endTime - currentView.startTime;
    const step = 1 + Math.ceil((length / 1000) * (action.payload - 1));
    let newTime = state.currentTime + step;
    if (newTime >= currentView.endTime) {
        newTime = currentView.startTime;
    }
    
    return { ...state, currentTime: newTime};
};

const backwardsReducer = (state: ViewsState, action: PayloadAction<number>) => {
    const currentView = state.views[state.views.length - 1];
    const length = currentView.endTime - currentView.startTime;
    const step = 1 + Math.ceil((length / 1000) * (action.payload - 1));
    let newTime = state.currentTime - step;
    if (newTime < currentView.startTime) {
        newTime = currentView.endTime - 1;
    }
    
    return { ...state, currentTime: newTime};
};

const zoomInReducer = (state: ViewsState, action: PayloadAction<TimeRange>) => {
    
    const currentView = state.views[state.views.length - 1];

    if (currentView.endTime - currentView.startTime === MIN_ZOOM_MILLISECONDS) {
        return;
    }

    let newView = intersectTimeRanges(action.payload, currentView);

    const regionDuration = newView.endTime - newView.startTime;

    if (regionDuration < MIN_ZOOM_MILLISECONDS) {
        newView.endTime = newView.startTime + MIN_ZOOM_MILLISECONDS;
    }

    if (!timeRangesAreEqual(currentView, newView)) {
        state.views.push(newView);
        state.currentTime = Math.max(state.currentTime, newView.startTime);
        state.currentTime = Math.min(state.currentTime, newView.endTime);
    }
}

const zoomOutReducer = (state: ViewsState) => {
    if (canZoomOut(state)) {
        state.views = state.views.slice(0, state.views.length - 1);
    }
}

const panReducer = (state: ViewsState, action: PayloadAction<PanAction>) => {
    const length = state.views[state.views.length - 1].endTime - state.views[state.views.length - 1].startTime;
    let newStartTime = action.payload.newStartTime;
    
    if (newStartTime < state.views[0].startTime) {
        newStartTime = state.views[0].startTime;
    }

    if (newStartTime + length > state.views[0].endTime) {
        newStartTime = state.views[0].endTime - length;
    }

    const newView = {startTime: newStartTime, endTime: newStartTime + length };

    if (!timeRangesAreEqual(state.views[state.views.length - 1], newView)) {
        state.views[state.views.length - 1] = newView;
    }
}

const setAnalysisRegionReducer = (state: ViewsState, action: PayloadAction<SetAnalysisRegionAction>) => { 
    if (action.payload.region.startTime <= action.payload.region.endTime) {
        state.analysisRegion = intersectTimeRanges(state.views[0], action.payload.region);
    }
};

const resetReducer = (state: ViewsState, action: PayloadAction<TimeRange>) => {
    state.views = [action.payload];
    state.currentTime = action.payload.startTime;
    state.analysisRegion = null;
}

const canZoomOut = (state: ViewsState) => state.views.length > 1;

const filterModeReducer = (state: ViewsState, action: PayloadAction<FilterMode>) => {
    state.filter.mode = action.payload;
}

const setIncludedMarketCentersReducer = (state: ViewsState, action: PayloadAction<string[]>) => { 
    state.includedMarketCenters = action.payload;
};

export const slice = createSlice({
    name: "views",
    initialState: initialState,
    reducers: {
        setCurrentTime: setCurrentTimeReducer,
        forwards: forwardsReducer,
        backwards: backwardsReducer,
        zoomIn: zoomInReducer,
        zoomOut: zoomOutReducer,
        pan: panReducer,
        setAnalysisRegion: setAnalysisRegionReducer,
        reset: resetReducer,
        filterMode: filterModeReducer,
        setIncludedMarketCenters: setIncludedMarketCentersReducer
    }
})

export const { setCurrentTime, forwards, backwards, zoomIn, zoomOut, pan, setAnalysisRegion, reset, filterMode, setIncludedMarketCenters } = slice.actions;

export const getCurrentView = (state : AppStore) => state.views.views.length > 0 ? state.views.views[state.views.views.length - 1] : null;
export const getCurrentTime = (state : AppStore) => state.views.views.length > 0 ? state.views.currentTime : null;
export const getCanZoomOut = (state : AppStore) => canZoomOut(state.views);
export const getAnalysisRegion = (state : AppStore) => state.views.analysisRegion;
export const getFilterMode = (state: AppStore) => state.views.filter.mode;
export const getIncludedMarketCenters = (state: AppStore) => state.views.includedMarketCenters;
export const tradesVisible = (state: AppStore) => state.trades.trades.length > 0 && state.views.filter.mode !== FilterMode.Quotes;
export const quotesVisible = (state: AppStore) => state.quotes.quotes.length > 0 && state.views.filter.mode !== FilterMode.Trades;
export const getVisibility = (state: AppStore) => ({ tradesVisible: tradesVisible(state), quotesVisible: quotesVisible(state) });

export default slice.reducer;
