import { Action, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStore } from ".";
import largestTriangleThreeBuckets from "../algorithms/lttb";
import { rangeFilter } from "../algorithms/rangeFilter";
import { IQuote, IQuoteDataPoint } from "../types";
import { getAnalysisRegion, getCurrentView, getCurrentTime, getIncludedMarketCenters } from "./viewsSlice";
import { createSelector } from 'reselect';
import mcCodes from "../mcCodes.json";
import { findMostRecent, partition } from "../algorithms/bbo";
import _ from 'lodash';
import BboWorker from "../workers/bbo-worker";

export const getQuotesStore = (state: AppStore) => state.quotes;
export const getQuotes = (state: AppStore) => state.quotes.quotes;

interface QuotesState {
    quotes: IQuote[],
    bboLoading: boolean,
    bestBids: IQuoteDataPoint<IQuote>[],
    bestOffers: IQuoteDataPoint<IQuote>[]
}

const initialState: QuotesState = {
    quotes: [],
    bboLoading: false,
    bestBids: [],
    bestOffers: []
};

const setQuotesReducer = (state: QuotesState, action: PayloadAction<IQuote[]>) => {
    state.quotes = action.payload;
}

const clearQuotesReducer = (state: QuotesState) => {
    state.quotes = [];
}

type BboData = {
    bestBids: IQuoteDataPoint<IQuote>[];
    bestOffers: IQuoteDataPoint<IQuote>[];
}

const setBboReducer = (state: QuotesState, action: PayloadAction<BboData>) => {
    state.bboLoading = false;
    state.bestBids = action.payload.bestBids;
    state.bestOffers = action.payload.bestOffers;
}

const clearBboReducer = (state: QuotesState, action: Action) => {
    state.bboLoading = true;
    state.bestBids = [];
    state.bestOffers = [];
}

const bboWorker = new BboWorker();

export const calculateBbo = createAsyncThunk(
    "quotes/calculateBbo",
    async (_, thunkApi) => {

        const state = thunkApi.getState() as AppStore;
        const filteredQuotes = getFilteredQuotes(state);

        const bestBidsTask = bboWorker.getBestBids(filteredQuotes);
        const bestOffersTask = bboWorker.getBestOffers(filteredQuotes);
        
        const bboData = ({
            bestBids: await bestBidsTask,
            bestOffers: await bestOffersTask
        })

        return bboData;
    }
);

export const slice = createSlice({
    name: "quotes",
    initialState: initialState,
    reducers: {
        setQuotes: setQuotesReducer,
        clearQuotes: clearQuotesReducer
    },
    extraReducers: builder => { builder
        .addCase(calculateBbo.pending, clearBboReducer)
        .addCase(calculateBbo.fulfilled, setBboReducer)
    }
})

export const { setQuotes, clearQuotes } = slice.actions;

export const getFilteredQuotes = createSelector(
    [getQuotes, getIncludedMarketCenters],
    (quotes, includedMarketCenters) => {
        return includedMarketCenters.length > 0 ? quotes.filter(q => includedMarketCenters.includes(q.mc)) : quotes;
    }
);

export const getBestBidsInCurrentView = createSelector(
    [getQuotesStore, getCurrentView],
    (store, currentView) => {

        const filteredQuotes = rangeFilter(
            store.bestBids,
            q => q.time,
            currentView!.startTime,
            currentView!.endTime,
            1);

        return largestTriangleThreeBuckets(
            filteredQuotes,
            1000,
            q => q.time,
            q => { return q.value ? q.value : 0 });
    }
);

export const getBestOffersInCurrentView = createSelector(
    [getQuotesStore, getCurrentView],
    (state, currentView) => {

        const filteredQuotes = rangeFilter(
            state.bestOffers,
            q => q.time,
            currentView!.startTime,
            currentView!.endTime,
            1);

        return largestTriangleThreeBuckets(
            filteredQuotes,
            1000,
            q => q.time,
            q => { return q.value ? q.value : 0 });
    }
);

export const getQuotesByMarketCenter = createSelector(
    [getFilteredQuotes],
    (quotes) => {

        return partition(
            quotes,
            q => q.mc,
        );
    }
);

export const getMostRecentQuotes = createSelector(
    [getQuotesByMarketCenter, getCurrentTime],
    (quotesDictionary, currentTime) => {

        let mostRecentQuotes: IQuote[] = [];

        for (const marketCode in quotesDictionary) {

            const quotes = quotesDictionary[marketCode];

            const quote = findMostRecent(
                quotes,
                q => q?.start,
                q => q?.end,
                currentTime ? currentTime : 0
            );

            if (quote) {
                mostRecentQuotes.push(quote);
            }
        }

        return mostRecentQuotes;
    }
)

export const getMostRecentBids = createSelector(
    [getMostRecentQuotes],
    (mostRecentQuotes) => {
        const mostRecentQuotesCopy = mostRecentQuotes.slice();
        mostRecentQuotesCopy.sort(sortBids);
        return mostRecentQuotesCopy;
    }
)

const sortBids = (a: IQuote, b: IQuote) => {
    // Sort by bid price DESC.
    if (a.low > b.low) return -1;
    if (a.low < b.low) return 1;
    // Then by bid quantity DESC.
    if (a.bidQuantity > b.bidQuantity) return -1;
    if (a.bidQuantity < b.bidQuantity) return 1;
    // Then by timestamp DESC.
    if (a.start > b.start) return -1;
    if (a.start < b.start) return 1;
    // Then by sipfeed seq ASC.    
    if (a.seqnum > b.seqnum) return 1;
    if (a.seqnum < b.seqnum) return -1;
    // Default case.
    return 0;
}

export const getMostRecentOffers = createSelector(
    [getMostRecentQuotes],
    (mostRecentQuotes) => {
        const mostRecentQuotesCopy = mostRecentQuotes.slice();
        mostRecentQuotesCopy.sort(sortOffers);
        return mostRecentQuotesCopy;
    }
)

const sortOffers = (a: IQuote, b: IQuote) => {
    // Sort by offer price ASC.
    if ((a.high !== 0 ? a.high : Number.MAX_VALUE) > (b.high !== 0 ? b.high : Number.MAX_VALUE)) return 1;
    if ((a.high !== 0 ? a.high : Number.MAX_VALUE) < (b.high !== 0 ? b.high : Number.MAX_VALUE)) return -1;
    // Then by offer quantity DESC.
    if (a.offerQuantity > b.offerQuantity) return -1;
    if (a.offerQuantity < b.offerQuantity) return 1;
    // Then by timestamp DESC.
    if (a.start > b.start) return -1;
    if (a.start < b.start) return 1;
    // Then by sipfeed seq ASC.    
    if (a.seqnum > b.seqnum) return 1;
    if (a.seqnum < b.seqnum) return -1;
    // Default case.
    return 0;
}

export interface ExchangeSummary {
    mc: string,
    highBid: number | null,
    lowBid: number | null,
    lowAsk: number | null,
    highAsk: number | null
}

export const getExchangeInfoInAnalysisRegion = createSelector(
    [getFilteredQuotes, getAnalysisRegion],
    (quotes, analysisRegion): ExchangeSummary[] => {

        const filteredQuotes: IQuote[] = getQuotesInAnalysisRegion(quotes);

        return getExchangeInfo(filteredQuotes);

        function getQuotesInAnalysisRegion(quotes: IQuote[]): IQuote[] {
            if (analysisRegion) {
                return rangeFilter(
                    quotes,
                    q => q.start,
                    analysisRegion!.startTime,
                    analysisRegion!.endTime,
                    1);
            } else {
                return quotes;
            }
        }

        function getExchangeInfo(quotes: IQuote[]): ExchangeSummary[] {
            const groups = _.groupBy(quotes, q => q.mc);

            const result = _.map(groups, (mcQuotes, key) => {

                return {
                    mc: key,
                    highBid: _.max(mcQuotes.map(q => q.low)) ?? null,
                    lowBid: _.min(mcQuotes.map(q => q.low)) ?? null,
                    lowAsk: _.min(mcQuotes.map(q => q.high)) ?? null,
                    highAsk: _.max(mcQuotes.map(q => q.high)) ?? null
                }
            });

            includeMissingExchanges();

            return result;

            function includeMissingExchanges() {
                for (const { code } of mcCodes) {
                    if (_.has(groups, code)) continue;

                    result.push({
                        mc: code,
                        highBid: null,
                        lowBid: null,
                        lowAsk: null,
                        highAsk: null
                    });
                }
            }
        }
    }
);

export const getPreviousQuoteInView = (state: AppStore) => {
    const currentTime = getCurrentTime(state) ?? 0;
    const currentView = getCurrentView(state);
    const index = state.quotes.quotes.findIndex(q => q.start >= currentTime);

    if (index > 0) {
        const previousQuote = state.quotes.quotes[index - 1];

        if (previousQuote.start >= currentView!.startTime) {
            return previousQuote;
        }
    }

    return null;
}

export const getNextQuoteInView = (state: AppStore) => {
    const currentTime = getCurrentTime(state) ?? 0;
    const currentView = getCurrentView(state);
    const nextQuote = state.quotes.quotes.find(q => q.start > currentTime);

    if (nextQuote && nextQuote.start < currentView!.endTime) {
        return nextQuote;
    }

    return null;
}

export default slice.reducer;
