import React from 'react';
import { Button, FontAwesomeIcon, Toggler } from '@nef/core';
import { useDispatch, useSelector } from 'react-redux';
import { getMode, getSpeed, PlaybackMode, PlaybackSpeed, setMode, setSpeed } from '../../store/playbackSlice';
import { Slider } from '@nef/lab';
import "./playback.css";
import classnames from 'classnames';

const PlaybackControls = () => {

    const dispatch = useDispatch();
    const mode = useSelector(getMode);
    const speed = useSelector(getSpeed);

    const handleSpeedChange = (_: any, val: number | number[]) => {
        const speed = Number(val) as PlaybackSpeed;

        dispatch(setSpeed(speed))
    };

    return (
        <div className="playback-controls__container">
            <div className="playback-controls__spacer" />
            
            <Toggler
                defaultValue={mode}
                size="sm"
                className="playback-controls"
                onClick={e => dispatch(setMode(e as PlaybackMode))}
            >
                <Button value="BACKWARDS">
                    <FontAwesomeIcon
                        iconClassName="fa-backward"
                        iconSetClassName="fas" />
                </Button>
                <Button value="PAUSED">
                    <FontAwesomeIcon
                        iconClassName="fa-pause"
                        iconSetClassName="fas" />
                </Button>
                <Button value="FORWARDS">
                    <FontAwesomeIcon
                        iconClassName="fa-forward"
                        iconSetClassName="fas" />
                </Button>
            </Toggler>
            
            <div className={classnames( { "playback-controls__slider-container--hidden": mode === "PAUSED" }, "playback-controls__slider-container")}>
                <span className="playback-controls__slider-number">{Math.ceil(speed)}x</span>
                <span className="playback-controls__slider-value">&minus;</span>
                <Slider
                    className="playback-controls__slider"
                    max={10}
                    min={1}
                    value={speed}
                    onChange={handleSpeedChange}
                    size="sm"
                />
                <span className="playback-controls__slider-icon">+</span>
            </div>
        </div>
    );
}

export default PlaybackControls