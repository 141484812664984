import { Parser } from "json2csv";
import { useSelector } from "react-redux";
import { getOpenReplay, Replay } from "../store/requestsSlice";
import downloadFile from "js-file-download";
import { getTradeAnalysis } from "../store/tradesSlice";
import { useMatomo } from "../utils/matomo";

const getFileName = (replay: Replay) => {

    const date = replay.details.start.toISODate();

    return `${replay.details.symbol}-${date}-TradeSummary.csv`;
}

const useExportAnalysisRegionTradeSummary: () => [boolean, () => void] = () => {

    const replay = useSelector(getOpenReplay);
    const tradeSummary = useSelector(getTradeAnalysis);
    const [trackEvent] = useMatomo();

    const enabled = replay !== null && replay.details.hasTrades;

    const exportTrades = () => {
        if (enabled && replay) {

            trackEvent(['export', 'export-analysis-region-trade-summary', `replay: ${replay.id}`]);

            const fileName = replay ? getFileName(replay) : '';

            const parser = new Parser({
                fields: [
                    "high",
                    "low",
                    "vwap",
                    "totalTrades",
                    "totalShares"
                ]
            });

            downloadFile(
                parser.parse([tradeSummary]),
                fileName
            );
        }
    };

    return [enabled, exportTrades];
}

export default useExportAnalysisRegionTradeSummary;