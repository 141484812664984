import { FunctionComponent } from "react";

interface CurrencyProps {
    amount: number,
    wholeNumbersOnly?: boolean,
    decimalPlaces?: number
}

const Currency : FunctionComponent<CurrencyProps> = (props) => {
    const { amount, wholeNumbersOnly, decimalPlaces } = props;
    const formatter = new Intl.NumberFormat(
        'en-US',
        {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: wholeNumbersOnly ? 0 : (decimalPlaces ?? 2),
            maximumFractionDigits: wholeNumbersOnly ? 0 : (decimalPlaces ?? 2)
        })

    return <>{!amount || amount === 0 ? '-' : formatter.format(amount)}</>
}

Currency.defaultProps = ({
    wholeNumbersOnly: false
});

export default Currency;