import { Header, Tag } from "@nef/core";
import { RichTable } from "@nef/lab";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getMostRecentOffers } from "../../store/quotesSlice";
import Currency from "../currency";
import MarketCenter from "../market-center";

const columns = [
    {
        accessor: "mc",
        Header: "Exchange",
        Cell: ({ value }: any) => <MarketCenter code={value} />,
        width: 75
    },
    {
        accessor: "offerQuantity",
        Header: "Quantity",
        isNumeric: true,
        width: 100
    },
    {
        accessor: "high",
        Header: "Price",
        isNumeric: true,
        Cell: ({ value }: any) => <Currency amount={value} />,
        width: 100
    }
];

const BestOffers = () => {
    
    const mostRecentOffers = useSelector(getMostRecentOffers);

    const bestOffer = mostRecentOffers[0];

    const nbboQuantity = mostRecentOffers
        .filter(offer => offer.high === bestOffer.high)
        .reduce((a, b) => a + b.offerQuantity, 0)

    return (
        <div className="bbo-panel__table">
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Header size={4}>Offers</Header>
                <Header size={3}><Tag type="success">NBBO: {nbboQuantity} @ <Currency amount={bestOffer?.high ?? 0}/></Tag></Header>
            </div>
            <RichTable
                options={{
                    columns: useMemo(() => columns, []),
                    data: useMemo(() => mostRecentOffers, [mostRecentOffers]),
                    disableSortBy: true,
                }}
                isPaginationEnabled
            />

        </div>
    )
}

export default BestOffers;
