import { useDispatch, useSelector } from "react-redux";
import { RichTable } from "@nef/lab";
import { Duration } from "luxon";
import Currency from "../currency";
import { getSelectedTrade, getTradesInAnalysisRegion, selectTrade } from "../../store/tradesSlice";
import React from "react";
import { ITrade } from "../../types";
import { findMinIndex } from "../../algorithms/binarySearch";

const numberFormatter = new Intl.NumberFormat(
    'en-US',
    {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })

const columns = [
    {
        Header: "Time",
        accessor: "timestamp",
        Cell: ({ value }: any) => <span>{Duration.fromObject({ milliseconds: value }).toISOTime()}</span>,
        width: 60
    },
    {
        Header: "Price",
        accessor: "price",
        isNumeric: true,
        Cell: ({ value }: any) => <span><Currency amount={value} decimalPlaces={4} /></span>,
        width: 50
    },
    {
        Header: "Quantity",
        accessor: "shares",
        isNumeric: true,
        Cell: ({ value }: any) => <span>{numberFormatter.format(value)}</span>,
        width: 50
    },
    {
        Header: "Canceled",
        accessor: "canceled",
        Cell: ({ value }: { value: boolean }) => <span>{value == null || value ? 'Canceled' : ''}</span>
    },
    {
        Header: "MC / SMC",
        accessor: "mc_smc",
        width: 50
    },
    {
        Header: "Sales Condition",
        accessor: "salescondition"
    }
];

const TradesTable = () => {

    const dispatch = useDispatch();
    const data = useSelector(getTradesInAnalysisRegion);
    const selectedTrade = useSelector(getSelectedTrade);

    const [pageIndex, setPageIndex] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(20);

    const [previousSelectedTrade, setPreviousSelectedTrade] = React.useState(null as ITrade | null);

    const memoData = React.useMemo(
        () => data
            .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
            .map(t => ({
                 ...t,
                 isSelected: t === selectedTrade, indicatorColor: t === selectedTrade ? 'primary' : '',
                mc_smc: t.submkt ? `${t.mktcenter} / ${t.submkt}` : t.mktcenter  })),
        [pageIndex, pageSize, data, selectedTrade]);

    const pageCount = Math.ceil(data.length / pageSize);

    if (pageCount > 0 && pageIndex >= pageCount) {
        setPageIndex(0);
    }

    if (selectedTrade) {

        if (selectedTrade !== previousSelectedTrade) {

            const index = findMinIndex(data, t => t.seqnum, selectedTrade.seqnum);

            if (index !== -1 && data[index] === selectedTrade) {
                const selectedPageIndex = Math.floor(index / pageSize);
                setPageIndex(selectedPageIndex);
            }

            setPreviousSelectedTrade(selectedTrade);
        }
    }

    return (
        <RichTable
            options={{
                columns: columns,
                data: memoData,
                initialState: {
                    pageIndex,
                    pageSize
                },
                pageCount: pageCount,
                totalDataLength: data.length,
                manualPagination: true,
            }}
            isPaginationEnabled
            isIndicatorColumnEnabled
            onPaginationChange={(index, size) => {
                setPageIndex(index);
                setPageSize(size);
            }}
            onRowClick={e => {
                const trade = e.row.original as ITrade;
                dispatch(selectTrade(trade));
            }}
        />
    );
}

export default TradesTable;
