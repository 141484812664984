import { FontAwesomeIcon } from "@nef/core";
import React from "react";
import { Duration } from "luxon";

type HandleProps = {
    value: number,
    valueToPercent: (value : number) => number,
    onMouseDown: (event: React.MouseEvent) => void,
    onTouchStart: (event: React.TouchEvent) => void,
    onPrevious: () => void,
    onNext: () => void
}

const Handle = (props : HandleProps) => {
    const { valueToPercent, value, onMouseDown, onTouchStart, onPrevious, onNext } = props;
    const left = valueToPercent(value) * 100;
    const displayValue = Duration.fromObject({milliseconds: value}).toISOTime();
    return (
        <div
            className="time-slider__handle"
            style={{ left: `${left}%` }}>
            <FontAwesomeIcon
                className="time-slider__handle__leftarrow"
                onClick={onPrevious}
                iconSetClassName="fas"
                iconClassName="fa-chevron-left"
                light
            />
            <span
                className="time-slider__handle__label"
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}>
                {displayValue}
            </span>
            <FontAwesomeIcon
                className="time-slider__handle__rightarrow"
                onClick={onNext}
                iconSetClassName="fas"
                iconClassName="fa-chevron-right"
                light
            />
        </div>
    );
}

export default Handle;