import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStore } from ".";
import largestTriangleThreeBuckets from "../algorithms/lttb";
import { rangeFilter } from "../algorithms/rangeFilter";
import { IAggregatedTrade, ITrade } from "../types";
import { getAnalysisRegion, getCurrentTime, getCurrentView, getIncludedMarketCenters } from "./viewsSlice";
import { createSelector } from 'reselect';
import { findMinIndex, findMaxIndex } from "../algorithms/binarySearch";

const getTradesStore = (state: AppStore) => state.trades;
export const getTrades = (state: AppStore) => getTradesStore(state).trades;

interface TradesState {
    trades: ITrade[]
    selectedTradeIndex: number | null;
}

const initialState: TradesState = {
    trades: [],
    selectedTradeIndex: null
};

type SetTradesAction = {
    trades: ITrade[]
};

const setTradesReducer = (state: TradesState, action: PayloadAction<SetTradesAction>) => ({
    ...state,
    trades: action.payload.trades,
    selectedTradeIndex: null
});

const clearTradesReducer = (state: TradesState) => ({
    ...state,
    trades: [],
    selectedTradeIndex: null
});

const selectTradeReducer = (state: TradesState, action: PayloadAction<ITrade | null>) => {
    if (action.payload) {
        const index = findMinIndex(state.trades, (t : ITrade) => t.seqnum, action.payload.seqnum);
        if (index !== -1 && state.trades[index].seqnum === action.payload.seqnum) {
            state.selectedTradeIndex = index;
        } else {
            state.selectedTradeIndex = null;
        }
    } else {
        state.selectedTradeIndex = null;
    }
};

export const slice = createSlice({
    name: "trades",
    initialState: initialState,
    reducers: {
        setTrades: setTradesReducer,
        clearTrades: clearTradesReducer,
        selectTrade: selectTradeReducer
    }
})

export const { setTrades, clearTrades, selectTrade } = slice.actions;

export const getFilteredTrades = createSelector(
    [getTrades, getIncludedMarketCenters],
    (trades, includedMarketCenters) => {
        return includedMarketCenters.length > 0 ? trades.filter(t => includedMarketCenters.includes(t.mktcenter)) : trades;
    }
);

export const getSelectedTrade = (state: AppStore) => state.trades.selectedTradeIndex !== null ? state.trades.trades[state.trades.selectedTradeIndex] : null;

export const getTradesInCurrentView = createSelector(
    [getFilteredTrades, getCurrentView],
    (trades, currentView) => {

        const filteredTrades = rangeFilter(
            trades,
            t => t.timestamp,
            currentView!.startTime,
            currentView!.endTime,
            0);

        return largestTriangleThreeBuckets(
            filteredTrades,
            1000,
            t => t.timestamp,
            t => t.shares);
    }
);

export const getMostRecentTrade = createSelector(
    [getFilteredTrades, getCurrentTime],
    (trades, currentTime) => {

        if (!currentTime) return null;
        
        const tradeIndex = findMaxIndex(trades, (t : ITrade) => t.timestamp, currentTime);
        
        if (tradeIndex === -1) return null;

        return trades[tradeIndex];
    }
);

export const getTradesInAnalysisRegion = createSelector(
    [getFilteredTrades, getAnalysisRegion],
    (trades, analysisRegion) => {

        if (analysisRegion === null) {
            return trades;
        }

        return rangeFilter(
            trades,
            t => t.timestamp,
            analysisRegion.startTime,
            analysisRegion.endTime,
            0
        );
    }
);

export const getTradeAnalysis = createSelector(
    [getTradesInAnalysisRegion],
    (trades) => {
        let low : (number | null) = null;
        let high : (number | null) = null;
        let totalTrades = 0;
        let totalShares = 0;
        let totalValueAmount = 0;

        for (let index = 0; index < trades.length; index++) {
            const trade = trades[index];
            if (!low || trade.price < low) { low = trade.price; }
            if (!high || trade.price > high) { high = trade.price; }
            totalTrades++;
            totalShares += trade.shares;
            totalValueAmount += trade.price * trade.shares;
        }

        const vwap = totalShares > 0 ? totalValueAmount / totalShares : null;

        return ({
            low,
            high,
            totalTrades,
            totalShares,
            vwap
        });
    }
);

export const getTradeSummaryInCurrentView = createSelector(
    [getFilteredTrades, getCurrentView],
    (trades, currentView) => {

        let buckets: IAggregatedTrade[] = [];

        if (currentView) {

            const bucketSize = (currentView.endTime - currentView.startTime) / 100;
            const startTime = bucketSize * Math.floor(currentView.startTime / bucketSize);
            const endTime = bucketSize * Math.ceil(currentView.endTime / bucketSize);

            for (let time = startTime; time < endTime; time += bucketSize) {

                let bucket = ({
                    startTime: time,
                    endTime: time + bucketSize - 1,
                    high: null,
                    low: null,
                    last: null,
                    volume: 0
                });

                buckets.push(bucket);
            }

            const filteredTrades = rangeFilter(
                trades,
                t => t.timestamp,
                startTime,
                endTime,
                0);

            filteredTrades.forEach(trade => {
                const bucketIndex = Math.floor((trade.timestamp - startTime) / bucketSize);
                if (bucketIndex >= 0 && bucketIndex < buckets.length) {
                    let bucket = buckets[bucketIndex];
                    bucket.volume = (bucket.volume ?? 0) + trade.shares;
                    bucket.last = trade.price;
                    bucket.low = bucket.low ? Math.min(bucket.low, trade.price) : trade.price;
                    bucket.high = bucket.high ? Math.max(bucket.high, trade.price) : trade.price;
                }
            });
        }

        return buckets;
    }
);

export const getPreviousTradeInView = (state: AppStore) => {
    const currentTime = getCurrentTime(state) ?? 0;
    const currentView = getCurrentView(state);
    const index = state.trades.trades.findIndex(q => q.timestamp >= currentTime);

    if (index > 0) {
        const previousTrade = state.trades.trades[index - 1];

        if (previousTrade.timestamp >= currentView!.startTime) {
            return previousTrade;
        }
    }

    return null;
}

export const getNextTradeInView = (state: AppStore) => {
    const currentTime = getCurrentTime(state) ?? 0;
    const currentView = getCurrentView(state);
    const nextTrade = state.trades.trades.find(q => q.timestamp > currentTime);

    if (nextTrade && nextTrade.timestamp < currentView!.endTime) {
        return nextTrade;
    }

    return null;
}

export default slice.reducer;