import { Button } from '@nef/core';
import useExportAnalysisRegionTrades from '../../utils/useExportAnalysisRegionTrades';

const ExportAnalysisRegionTrades = () => {

    const [enabled, exportAnalysisRegionTrades] = useExportAnalysisRegionTrades();

    return (
        <Button
            onClick={exportAnalysisRegionTrades}
            color="secondary"
            outline size="sm"
            disabled={!enabled}>
            Export
        </Button>
    );
}

export default ExportAnalysisRegionTrades