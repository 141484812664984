import { DataArray, Flex, Header } from "@nef/core";
import { useSelector } from "react-redux";
import mcCodes from "../../mcCodes.json";
import { Duration } from 'luxon';
import { getMostRecentTrade } from "../../store/tradesSlice";
import { formatTradePrice } from "../../utils/currency";

interface LastSaleReplayProps {
    layout: "horizontal" | "vertical";
}

interface LastSaleData {
    price: string;
    quantity: string;
    exchange: string;
    time: string;
}

const HorizontalLayout = (data: LastSaleData) => (
    <Flex justifyContent="space-around">
        <span>Price: {data.price}</span>
        <span>Quantity: {data.quantity}</span>
        <span>Exchange: {data.exchange}</span>
        <span>Time: {data.time}</span>
    </Flex>
)

const VerticalLayout = (data: LastSaleData) => (
    <DataArray data={[
        { label: 'Price', value: data.price },
        { label: 'Quantity', value: data.quantity },
        { label: 'Exchange', value: data.exchange },
        { label: 'Time', value: data.time }
    ]} />
)

const LastSaleReplay = ({ layout }: LastSaleReplayProps) => {

    const lastSaleInfo = useSelector(getMostRecentTrade);

    const data = {
        price: formatTradePrice(lastSaleInfo?.price, "N/A"),
        quantity: lastSaleInfo?.shares.toString() || "N/A",
        exchange: mcCodes.find((item) => item.code === lastSaleInfo?.mktcenter)?.mic ?? "N/A",
        time: Duration.fromObject({ milliseconds: lastSaleInfo?.timestamp }).toISOTime()
    };

    return (
        <div>
            <Header size={4}>Last Sale</Header>
            { layout === "vertical" ? <VerticalLayout {...data} /> : <HorizontalLayout {...data} /> }
        </div>
    )
}

export default LastSaleReplay;