import { useCallback, useEffect, useState } from "react";

const useToggle : (initialIsOpen: boolean) => [boolean, () => void] = (initialIsOpen) => {

    const [isOpen, setIsOpen] = useState(initialIsOpen);

    const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    useEffect(() => {

        if (isOpen) {
            window.addEventListener('click', ensureClosed);

            return () => {
                window.removeEventListener('click', ensureClosed);
            }
        }

        function ensureClosed() {
            if (isOpen) {
                setIsOpen(false);
            }
        }
    }, [isOpen]);

    return [isOpen, toggle];
}

export default useToggle;
