import { uniq } from "lodash";
import { IQuote, IQuoteDataPoint } from "../../../types";

const getChanges = (bestBids: IQuoteDataPoint<IQuote>[], bestOffers: IQuoteDataPoint<IQuote>[]): number[] => {
    return uniq([
        ...getTimeChanges(bestBids),
        ...getTimeChanges(bestOffers)
    ]);

    function getTimeChanges(data: IQuoteDataPoint<IQuote>[]) {
        let changes = [];

        for (let i = 0; i < data.length; i++) {
            if (i === 0) continue;

            const { value: current, time } = data[i];
            const { value: prev } = data[i-1];

            if (prev === current) continue;
            changes.push(time);
        }

        return changes;
    }
}

export { getChanges };
