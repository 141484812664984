import { Navbar, NavbarItem, NavbarLogo, NavbarRightSection } from '@nef/core';
import { useDispatch } from 'react-redux';
import AuthNav from '../auth-nav';
import { closeReplay } from '../../store/requestsSlice';

const AppNav = () => {

    const dispatch = useDispatch();

    return (
        <Navbar className="app__nav" doHideOnScroll={false}>
            <NavbarLogo />
            <NavbarItem id="appTitle" active onClick={() => dispatch(closeReplay())}>Market Replay</NavbarItem>
            <NavbarRightSection>
                <AuthNav />
            </NavbarRightSection>
        </Navbar>
    );
}

export default AppNav;