import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FontAwesomeIcon, FormRadioCheckboxButton } from "@nef/core"
import { useFilterMode } from "./useFilterMode";
import { FilterMode } from "../../store/viewsSlice";
import useToggle from "../../utils/useToggle";
import { useState } from "react";
import ExchangeFilter from "../exchange-filter";

const ChartFilters = () => {

    const [isOpen, toggle] = useToggle(false);
    const { enabled, mode, setMode } = useFilterMode();

    const [exchangeFilterOpen, setExchangeFilterOpen] = useState(false);

    return (
        <>
            <Dropdown isOpen={isOpen} toggle={toggle}>
                <DropdownToggle ghost>
                    <FontAwesomeIcon iconClassName="fa-filter" />
                </DropdownToggle>
                <DropdownMenu maxContent>
                    <DropdownItem type="header">Data Sets</DropdownItem>
                    <DropdownItem onClick={() => setMode(FilterMode.All)}>
                        <FormRadioCheckboxButton
                            type="radio"
                            label="All"
                            value="all"
                            defaultChecked={mode === FilterMode.All}
                            disabled={!enabled}
                        />
                    </DropdownItem>
                    <DropdownItem onClick={() => setMode(FilterMode.Trades)}>
                        <FormRadioCheckboxButton
                            type="radio"
                            label="Trades"
                            value="trades"
                            defaultChecked={mode === FilterMode.Trades}
                            disabled={!enabled}
                        />
                    </DropdownItem>
                    <DropdownItem onClick={() => setMode(FilterMode.Quotes)}>
                        <FormRadioCheckboxButton
                            type="radio"
                            label="Quotes"
                            value="quotes"
                            defaultChecked={mode === FilterMode.Quotes}
                            disabled={!enabled}
                        />
                    </DropdownItem>
                    <DropdownItem type="divider" />
                    <DropdownItem type="header">Exchanges</DropdownItem>
                    <DropdownItem onClick={() => setExchangeFilterOpen(true)}>
                        Select Exchanges
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <ExchangeFilter isOpen={exchangeFilterOpen} onClose={() => setExchangeFilterOpen(false)} />
        </>
    );
}

export default ChartFilters;
