import { DateTime, Duration } from "luxon";

const parseDate = (val: string | undefined) => {
    return DateTime.fromISO(val ?? '', {zone: 'America/New_York'});
}

const timeRegex = new RegExp(/^([0]?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s*$/);
const parseTime = (val: string | undefined) => {
    
    if (val === undefined) {
        // allow undefined values to be handled by "required"
        return Duration.fromMillis(0); 
    }

    const matches = timeRegex.exec(val);

    if (matches === null || matches.length !== 3) {
        return Duration.invalid('Invalid time format.');
    }

    const hours = parseInt(matches[1]);

    if (isNaN(hours) || hours < 0 || hours > 23) {
        return Duration.invalid('Hours must be a number between 0 and 23.');
    }

    const minutes = parseInt(matches[2]);

    if (isNaN(minutes) || minutes < 0 || minutes > 59) {
        return Duration.invalid('Minutes must be a number between 0 and 59.');
    }

    return Duration.fromObject({hours, minutes });
}

export { parseDate, parseTime }
