import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { checkSession } from './store/session';
import { loadReplays } from './store/requestsSlice';
import { setupMatomo } from './utils/matomo';

fetch('/config')
  .then(res => res.json())
  .then(c => setupMatomo(c.url, c.siteId))
  .then(() => {
    store.dispatch(checkSession());
    store.dispatch(loadReplays());
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
