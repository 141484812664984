import { useSelector } from "react-redux";
import { getOpenReplay, Replay } from "../store/requestsSlice";
import downloadFile from "js-file-download";
import { getTrades } from "../store/tradesSlice";
import { useMatomo } from "../utils/matomo";
import CsvWorker from '../workers/csv-worker';

const csvWorker = new CsvWorker();

const getFileName = (replay: Replay) => {

    const date = replay.details.start.toISODate();

    return `${replay.details.symbol}-${date}-Trades.csv`;
}

const useExportTrades : () => [boolean, () => void] = () => {

    const replay = useSelector(getOpenReplay);
    const trades = useSelector(getTrades);
    const [trackEvent] = useMatomo();

    const enabled = replay !== null && replay.details.hasTrades;

    const exportTrades = () => {
        if (enabled && replay) {

            trackEvent(['export', 'export-trades', `replay: ${replay.id}`, trades.length]);

            const fileName = getFileName(replay);

            const startDate = replay.details.start;
            
            const startMs = startDate.startOf("day").toMillis();

            csvWorker.convertTradesToCsv(startMs, trades)
                .then(csv => downloadFile(csv, fileName))
                .catch(err => console.error(err));
        }
    };

    return [enabled, exportTrades];
}

export default useExportTrades;