import { Replay } from "../../store/requestsSlice";
import { DateTime} from "luxon";

const getEmailContent = (request: Replay) => {
    const text = `The sender would like to share a NASDAQ Market Replay with you.
Replay Information:

Symbol: ${request.details.symbol}
Date: ${request.details.start.toISODate()}
Time: ${request.details.start.toLocaleString(DateTime.TIME_24_SIMPLE)} - ${request.details.end.toLocaleString(DateTime.TIME_24_SIMPLE)}

Go to https://data.nasdaq.com/mr.aspx to download the replay application and request this replay direct from NASDAQ.

NASDAQ Market Replay - Rewind and replay the market: NASDAQ Market Replay provides an extremely powerful replay and analysis of NASDAQ trading, allowing users to view the consolidated order book at any point in time.
`

    return encodeURI(text);
}

export default getEmailContent;
