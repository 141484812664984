import { Box, Flex, Section, SectionBody, SectionHeader } from "@nef/core";
import PlaybackControls from "../components/playback-controls";
import TimeSlider from "../components/time-slider";
import ExportBidsAndOffers from "../components/export-bids-and-offers";
import LastSaleReplay from "../components/last-sale";
import BestBids from "../components/best-bids";
import BestOffers from "../components/best-offers";
import "./PointInTimeAnalysisPanel.css";
import { useSelector } from "react-redux";
import { getVisibility } from "../store/viewsSlice";
import styled from "styled-components";

const BBOPanelLastSale = styled.div<{ quotesVisible: boolean }>`
@media (min-width: 1200px) {
    width: ${({ quotesVisible }) => quotesVisible ? "20%" : "100%" };
    padding-right: ${({ quotesVisible }) => quotesVisible ? "20px" : "0" };
    border-right: ${({ quotesVisible }) => quotesVisible ? "1px solid gray" : "none" };
    padding-top: ${({ quotesVisible }) => quotesVisible ? "0" : "20px" };
    font-size: ${({ quotesVisible }) => quotesVisible ? "initial" : "120%" };
}
`;

const BBOPanelBestBids = styled.div<{ tradesVisible: boolean }>`
@media (min-width: 1200px) {
    width: ${({ tradesVisible }) => tradesVisible ? "40%" : "50%" };
    padding-right: 20px;
    padding-left: 20px;
    border-right: 1px solid gray;
}
`;

const BBOPanelBestOffers = styled.div<{ tradesVisible: boolean }>`        
@media (min-width: 1200px) {
        width: ${({ tradesVisible }) => tradesVisible ? "40%" : "50%" };
        padding-left: 20px;
}
`;

const PointInTimeAnalysisPanel = () => {
    const { tradesVisible, quotesVisible } = useSelector(getVisibility);

    return (
        <Section>
            <SectionHeader title="Point in Time Analysis">
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    &nbsp;
            <div style={{ height: '30px', position: 'absolute', display: 'grid', gridTemplateColumns: '4fr 1fr', top: '-14px', left: '50px', right: '0' }}>
                        <TimeSlider margin={0.15} />
                        <PlaybackControls />
                    </div>
                </div>
            </SectionHeader>
            <SectionBody>
                <div className="bbo-panel">
                    {
                        tradesVisible && (
                            <BBOPanelLastSale quotesVisible={quotesVisible}>
                                <LastSaleReplay layout={quotesVisible ? "vertical" : "horizontal"} />
                            </BBOPanelLastSale>
                        )
                    }
                    
                    {
                        quotesVisible && (
                            <>
                                <BBOPanelBestBids tradesVisible={tradesVisible}>
                                    <BestBids />
                                </BBOPanelBestBids>

                                <BBOPanelBestOffers tradesVisible={tradesVisible}>
                                    <BestOffers />
                                </BBOPanelBestOffers>
                            </>
                        )
                    }
                </div>

                { quotesVisible && (
                    <Box paddingTop={4}>
                        <Flex justifyContent="flex-end">
                            <ExportBidsAndOffers />
                        </Flex>
                    </Box>
                )}
                
            </SectionBody>
        </Section>
    )
}

export default PointInTimeAnalysisPanel;