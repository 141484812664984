import React from 'react';
import { Box, Flex, Section, SectionBody, SectionHeader, Tab, TabContent, TabPane, Tabs } from '@nef/core';
import TimespanDisplay from '../components/timespan-display';
import TradesAnalyis from '../components/trades-analysis';
import TradesTable from '../components/trades-table';
import QuotesTable from '../components/quotes-table';
import ExportAnalysisRegionTrades from '../components/export-analysis-region-trades';
import ExportAnalysisRegionTradeSummary from '../components/export-analysis-region-trade-summary';
import ExportAnalysisRegionQuoteSummary from '../components/export-analysis-region-quote-summary';
import { tradesVisible, quotesVisible } from "../store/viewsSlice";
import { connect, ConnectedProps } from "react-redux";
import { AppStore } from '../store';

const mapState = (state: AppStore) => ({
    tradesVisible: tradesVisible(state),
    quotesVisible: quotesVisible(state)
});

const connector = connect(mapState);

type AnalysisPanelProps = ConnectedProps<typeof connector>;

interface AnalysisPanelState {
    activeTabId: number;
}

class AnalysisPanel extends React.Component<AnalysisPanelProps, AnalysisPanelState> {

    constructor(props: any) {
        super(props);
        this.state = { activeTabId: props.tradesVisible ? 1 : 3 }
        this.onSelectTab = this.onSelectTab.bind(this);
        this.isActive = this.isActive.bind(this);
    }

    onSelectTab(tabId: number) {
        this.setState(s => ({ ...s, activeTabId: tabId }));
    }

    isActive(tabId: number) {
        return this.state.activeTabId === tabId;
    }

    componentDidUpdate(prevProps: AnalysisPanelProps, prevState: AnalysisPanelState) {
        const { quotesVisible, tradesVisible } = this.props;
        const { activeTabId } = prevState;

        if (!quotesVisible && !tradesVisible) {
            return;
        }

        switch(activeTabId) {
            case 1:
            case 2:
                if (!tradesVisible) {
                    this.setState({ activeTabId: 3 });
                }
                break;
            case 3:
                if (!quotesVisible) {
                    this.setState({ activeTabId: 1 });
                }
                break;
        }
    }

    render() {
        return (
            <Section style={{ marginBottom: '24px' }}>
                <SectionHeader title="Analysis Region">
                    <TimespanDisplay />
                </SectionHeader>
                <SectionBody>
                    <Tabs>
                        {
                            this.props.tradesVisible && (
                                <>
                                    <Tab
                                        active={this.isActive(1)}
                                        onClick={() => this.onSelectTab(1)}>
                                        Trades
                                    </Tab>
                                    <Tab
                                        active={this.isActive(2)}
                                        onClick={() => this.onSelectTab(2)}>
                                        Trade Summary
                                    </Tab>
                                </>
                            )
                        }
                        {
                            this.props.quotesVisible && (
                                <Tab
                                    active={this.isActive(3)}
                                    onClick={() => this.onSelectTab(3)}>
                                    Quote Summary
                                </Tab>
                            )
                        }
                    </Tabs>
                    <TabContent activeTabId={this.state.activeTabId}>
                        { 
                            this.props.tradesVisible && (
                                <>
                                    <TabPane tabId={1}>
                                        <TradesTable />
                                        <Box paddingTop={4}>
                                            <Flex justifyContent="flex-end">
                                                <ExportAnalysisRegionTrades />
                                            </Flex>
                                        </Box>
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <TradesAnalyis />
                                        <Box paddingTop={4}>
                                            <Flex justifyContent="flex-end">
                                                <ExportAnalysisRegionTradeSummary />
                                            </Flex>
                                        </Box>
                                    </TabPane>
                                </>
                            )
                        }
                        {
                            this.props.quotesVisible && (
                                <TabPane tabId={3}>
                                    <QuotesTable />
                                    <Box paddingTop={4}>
                                        <Flex justifyContent="flex-end">
                                            <ExportAnalysisRegionQuoteSummary />
                                        </Flex>
                                    </Box>
                                </TabPane>
                            )
                        }
                    </TabContent>
                </SectionBody>
            </Section>
        );
    }
}

export default connector(AnalysisPanel);
