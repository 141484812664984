// Finds the last index of an item in a sorted array with the maximal key less than or equal to a target value.
// Time: O(log n)
// Space: O(1)
export function findMaxIndex<T>(
    data: T[],
    keyFunc: (item: T) => number,
    target: number
) {
    let left = -1;
    let right = data.length - 1;

    while (left < right) {
        const mid = Math.ceil((left + right) / 2);
        const val = keyFunc(data[mid]);

        if (val > target) {
            right = mid - 1;
        } else {
            left = mid;
        }
    }

    return left;
}

// Finds the first index of an item in a sorted array with the minimal key greater than or equal to a target value.
// Time: O(log n)
// Space: O(1)
export function findMinIndex<T>(
    data: T[],
    keyFunc: (item: T) => number,
    target: number
) {
    let left = 0;
    let right = data.length;

    while (left < right) {
        const mid = Math.floor((left + right) / 2);
        const val = keyFunc(data[mid]);

        if (val < target) {
            left = mid + 1;
        } else {
            right = mid;
        }
    }

    return right === data.length ? -1 : right;
}
