const tradeFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 4,
    maximumFractionDigits: 4
});

function formatTradePrice(price: number | null | undefined, substituteWith: string = "-") {
    if (price == null || price === undefined || isNaN(price)) {
        return substituteWith;
    }

    return tradeFormatter.format(price);
}

export { formatTradePrice };
