import { IQuote } from "../../types";
import { Modal, ModalBody, ModalHeader, SimpleTable } from "@nef/core";
import MarketCenter from "../market-center";


interface QuoteDetailProps {
    quotes: IQuote[] | null,
    onClose: () => void;
}

const columns = [
    { value: "exchange", label: "Exchange" },
    { value: "low", label: "Bid" },
    { value: "bidQuantity", label: "Bid Qty." },
    { value: "high", label: "Ask" },
    { value: "offerQuantity", label: "Ask Qty." },
    { value: "seqnum", label: "Seq. Num." }
]

const QuoteDetails = ({ quotes, onClose }: QuoteDetailProps) => {
    if (!quotes) return null;

    const rows = quotes.map(quote => ({
        ...quote,
        exchange: (<MarketCenter code={quote.mc} />)
    }));

    return (
        <Modal size="sm" isOpen={true} toggle={onClose}>
            <ModalHeader toggle={onClose}>Quote Summary</ModalHeader>
            <ModalBody>
                <SimpleTable
                    columns={columns}
                    data={rows}
                />
            </ModalBody>
        </Modal>

    )
}

export default QuoteDetails;
