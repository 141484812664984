import { useDispatch, useSelector } from "react-redux";
import { getOpenReplay } from "../../store/requestsSlice";
import { getFilterMode, filterMode, FilterMode } from "../../store/viewsSlice";

export const useFilterMode = () => {
    const mode = useSelector(getFilterMode);

    const replay = useSelector(getOpenReplay);

    const dispatch = useDispatch();

    const enabled = replay && replay.details.hasQuotes && replay.details.hasTrades;

    const setMode = (newMode: FilterMode) => {
        if (enabled) {
            dispatch(filterMode(newMode))
        }
    };

    return { enabled, mode, setMode };
}