import React, { useMemo } from "react";
import { RichTable } from "@nef/lab";
import { Header, Tag } from "@nef/core";
import Currency from "../currency";
import { useSelector } from "react-redux";
import { getMostRecentBids } from "../../store/quotesSlice";
import MarketCenter from "../market-center";

const columns = [
    {
        accessor: "mc",
        Header: "Exchange",
        Cell: ({ value }: any) => <MarketCenter code={value} />,
        width: 75
    },
    {
        accessor: "bidQuantity",
        Header: "Quantity",
        isNumeric: true,
        width: 75
    },
    {
        accessor: "low",
        Header: "Price",
        isNumeric: true,
        Cell: ({ value }: any) => <Currency amount={value} />,
        width: 100
    },
];

const BestBids = () => {

    const mostRecentBids = useSelector(getMostRecentBids);
    
    const bestBid = mostRecentBids[0];
    const nbboQuantity = mostRecentBids
        .filter(bid => bid.low === bestBid.low)
        .reduce((sum, bid) => sum + bid.bidQuantity, 0);

    return (
        <div className="bbo-panel__table">
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Header size={4}>Bids</Header>
                <Header size={3}><Tag type="primary">NBBO: {nbboQuantity} @ <Currency amount={bestBid?.low ?? 0}/></Tag></Header>
            </div>
            <RichTable
                options={{
                    columns: useMemo(() => columns, []),
                    data: useMemo(() => mostRecentBids, [mostRecentBids]),
                    disableSortBy: true,
                }}
                isPaginationEnabled
            />
        </div>
    )

}

export default BestBids;
