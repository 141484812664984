import React, { createRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppStore } from '../../store';
import { getCurrentTime, getCurrentView, setCurrentTime } from '../../store/viewsSlice';
import Track from './track';
import Handle from './handle';
import './time-slider.css';
import { step } from '../../store/playbackSlice';

type TimeSliderProps = ConnectedProps<typeof connector> & { margin: number};

class TimeSlider extends React.Component<TimeSliderProps> {

    private sliderRef = createRef<HTMLDivElement>();
    private sliderStart : number = 0;
    private sliderLength : number = 0;

    constructor(props : TimeSliderProps) {
        super(props);

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        
        this.onClick = this.onClick.bind(this);
    }

    onStart() {
        this.sliderStart = this.getSliderStart();
        this.sliderLength = this.getSliderLength();
    }

    positionToPercent(position : number) {
        return ((position - this.sliderStart) / this.sliderLength);
    }

    valueToPercent(value : number) {

        let percent;
        if (value <= this.props.currentView!.startTime) {
            percent = 0;
        } else if (value >= this.props.currentView!.endTime) {
            percent = 1;
        } else {
            const length = this.props.currentView!.endTime - this.props.currentView!.startTime;
            percent = (value - this.props.currentView!.startTime) / length;
        }

        const m = this.props.margin;
        percent = m + percent * (1 - 2 * m);

        return Math.round(percent * 1000) / 1000;
    }

    percentToValue(percent : number) {

        const m = this.props.margin;
        percent = (percent - m) / (1 - 2 * m)

        if (percent <= 0) return this.props.currentView!.startTime;
        if (percent >= 1) return this.props.currentView!.endTime - 1;

        const length = this.props.currentView!.endTime - this.props.currentView!.startTime;
        const unroundedValue = this.props.currentView!.startTime + (length * percent);

        return Math.round(unroundedValue * 1000) / 1000;
    }

    onMove(position : number) {
        const percent = this.positionToPercent(position);
        const value = this.percentToValue(percent);

        if (value !== this.props.currentTime) {
            this.props.setCurrentTime(value);
        }
    }

    onEnd() {
    }

    getSliderStart() {
        const slider = this.sliderRef.current;
        if (!slider) {
            return 0;
        }
        
        const rect = slider.getBoundingClientRect();
        return rect.left;
    }

    getSliderLength() {
        const slider = this.sliderRef.current;
        if (!slider) {
            return 0;
        }

        return slider.clientWidth;
    }

    onClick(e : React.MouseEvent) {
        const position = e.pageX;
        this.onStart();
        this.onMove(position);
    }

    onMouseDown(e : React.MouseEvent) {
        this.onStart();
        this.addDocumentEvents('mouse');
    }

    onMouseMove(e : MouseEvent) {
        const position = e.pageX;
        this.onMove(position);
    }

    onTouchStart(e : React.TouchEvent) {
        this.onStart();
        this.addDocumentEvents('touch');
    }

    onTouchMove(e : TouchEvent) {
        const position = e.touches[0].pageX;
        this.onMove(position);
    }

    addDocumentEvents(type : 'touch'|'mouse') {
        if (type === 'touch') {
            window.addEventListener('touchmove', this.onTouchMove);
            window.addEventListener('touchend', this.onTouchEnd);
        } else if (type === 'mouse') {
            window.addEventListener('mousemove', this.onMouseMove);
            window.addEventListener('mouseup', this.onMouseUp);
        }
    }

    onTouchEnd() {
        window.removeEventListener('touchmove', this.onTouchMove);
        window.removeEventListener('touchend', this.onTouchEnd);
        this.onEnd();
    }

    onMouseUp() {
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
        this.onEnd();
    }

    render() {
        const { currentTime, goToNext, goToPrevious } = this.props;
        const valueToPercent = this.valueToPercent.bind(this);
        return (
            <div className="time-slider" ref={this.sliderRef}>
                <div className="time-slider__inner">
                    <Track
                        onClick={this.onClick} />
                    <Handle
                        valueToPercent={valueToPercent}
                        value={currentTime ?? 0}
                        onMouseDown={this.onMouseDown}
                        onTouchStart={this.onTouchStart}
                        onPrevious={goToPrevious}
                        onNext={goToNext} />
                </div>
            </div>
        );
    }
}


const mapState = (store: AppStore) => ({
    currentView: getCurrentView(store),
    currentTime: getCurrentTime(store)
});

const mapDispatch = ({
    setCurrentTime: (time: number) => setCurrentTime({time}),
    goToNext: () => step("next"),
    goToPrevious: () => step("previous")
})

const connector = connect(mapState, mapDispatch)

export default connector(TimeSlider);

