export function setupMatomo(url, siteId) {
    if (url) {
        console.log(`Configuring Matomo with url '${url}' and siteId '${siteId}'.`);

        var _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function () {
            var u = `${url}/`;
            _paq.push(['setTrackerUrl', u + 'matomo.php']);
            _paq.push(['setSiteId', `${siteId}`]);
            var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.type = 'text/javascript'; g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
        })();
    } else {
        console.log('Skipping matomo setup.');
    }
}