import { createContext } from "react";

const initialState: number[] = [];

const Context = createContext(initialState);
const { Provider, Consumer } = Context;

export {
    Context as QuoteLineContext,
    Provider as QuoteLineContextProvider,
    Consumer as QuoteLineContextConsumer
};
