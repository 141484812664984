import React from "react";
import { Formik, FormikHelpers, Form, FormikProps, Field } from "formik";
import { ReplayRequestSchema, RequestType, ReplayRequestForm, getReplayDetails } from "./ReplayRequestSchema";
import FormItem, { FieldType } from "../form-item";
import { Box, Button, CSSGrid, Flex, FormRadioCheckboxButton, FormRadioCheckboxGroup } from "@nef/core";
import { useDispatch } from "react-redux";
import { addReplay } from "../../store/requestsSlice";
import SymbolField from "../symbol-field";
import { DateTime } from "luxon";

const defaultRequest: ReplayRequestForm = {
    symbol: "NDAQ",
    date: DateTime.now().toISODate(),
    start: "09:00",
    end: "09:10",
    requestType: RequestType.All
};

interface RequestFormProps {
    onConfirm?: () => void;
}

const RequestForm = ({ onConfirm }: RequestFormProps) => {
    const dispatch = useDispatch();

    const handleSubmit = (values: ReplayRequestForm, { setSubmitting }: FormikHelpers<ReplayRequestForm>) => {
        const replayDetails = getReplayDetails(values);

        dispatch(addReplay(replayDetails));

        setSubmitting(false);

        if (onConfirm) { onConfirm(); }
    }

    return (
        <div>
            <Formik
                initialValues={defaultRequest}
                validationSchema={ReplayRequestSchema}
                onSubmit={handleSubmit}
            >
                {({ values, isValid, isSubmitting, setFieldValue }: FormikProps<any>) => (
                    <Form className="form">
                        <SymbolField name="symbol" initialValue={values.symbol} onUpdate={(val) => {
                            setFieldValue("symbol", val);
                        }} />

                        <FormItem name="date" type={FieldType.Date} />

                        <CSSGrid cols={2} gap="3em">
                            <FormItem name="start" label="Start Time" type={FieldType.Time} />
                            <FormItem name="end" label="End Time" type={FieldType.Time} />
                        </CSSGrid>

                        <Field
                            as={FormRadioCheckboxGroup}
                            name="requestType"
                            type="radio"
                            inline
                            value={values.requestType}
                            label="Data Sets"
                        >
                            <FormRadioCheckboxButton value={RequestType.All} label="All" />
                            <FormRadioCheckboxButton value={RequestType.Quotes} label="Quotes" />
                            <FormRadioCheckboxButton value={RequestType.Trades} label="Trades" />
                        </Field>
                        
                        <Box marginTop={4}>
                            <Flex justifyContent="end">
                                <Button
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    Download
                            </Button>
                            </Flex>
                        </Box>

                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default RequestForm;
