type TrackProps = {
    onClick: (event: React.MouseEvent) => void
}

const Track = (props: TrackProps) => {

    return (
        <div className="time-slider__track"
            onClick={props.onClick} />
    );
}

export default Track;
