import NumberFormat from "react-number-format";
import { DateTime, Interval } from 'luxon';

interface TimespanFieldProps {
    value?: string;
    disabled: boolean;
    onValidInput: (milliseconds: number) => void;
}

const timePattern = /^\d?\d:\d\d:\d\d\.[0-9]{1,3}/;
const formatString = "HH:mm:ss.S";

const isValid = (val: string) => {
    return timePattern.test(val) && DateTime.fromFormat(val, formatString).isValid
}

const msSinceStartOfDay = (val: string) => {
    const time = DateTime.fromFormat(val, formatString);
    const startOfDay = time.startOf("day");

    return Interval.fromDateTimes(startOfDay, time).length("milliseconds");
}

const TimespanField = ({
    value, disabled, onValidInput: onChange
}: TimespanFieldProps) => {
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (isValid(value)) {
            const ms = msSinceStartOfDay(value);

            onChange(ms);
        }
    }

    return (
        <NumberFormat
            className="timespan-display__input"
            value={value}
            format="##:##:##.###"
            placeholder="00:00:00.000"
            disabled={disabled}
            onChange={handleChange}
        />
    )
}

export default TimespanField;
