import { Parser } from "json2csv";
import { useSelector } from "react-redux";
import { getMostRecentBids, getMostRecentOffers } from "../store/quotesSlice";
import { getOpenReplay, Replay } from "../store/requestsSlice";
import downloadFile from "js-file-download";
import { useMatomo } from "./matomo";
import { getCurrentTime } from "../store/viewsSlice";
import marketCenters from "../mcCodes.json";
import { DateTime, Duration } from "luxon";

const getFileName = (replay: Replay) => {

    const date = replay.details.start.toISODate();

    return `${replay.details.symbol}-${date}-Bids-and-Offers.csv`;
}

const useExportBidsAndOffers: () => [boolean, () => void] = () => {

    const replay = useSelector(getOpenReplay);
    const currentTime = useSelector(getCurrentTime);
    const mostRecentBids = useSelector(getMostRecentBids);
    const mostRecentOffers = useSelector(getMostRecentOffers);
    const [trackEvent] = useMatomo();

    const enabled = replay !== null && replay.details.hasQuotes && currentTime !== null;

    const exportQuotes = () => {
        if (enabled && replay && currentTime) {

            trackEvent(['export', 'export-bids-and-offers', `replay: ${replay.id}`]);

            const fileName = replay ? getFileName(replay) : '';

            const bestBid = mostRecentBids[0];

            const nbboBidQuantity = mostRecentBids
                .filter(bid => bid.low === bestBid.low)
                .reduce((sum, bid) => sum + bid.bidQuantity, 0);

            const bestOffer = mostRecentOffers[0];

            const nbboOfferQuantity = mostRecentOffers
                .filter(offer => offer.high === bestOffer.high)
                .reduce((a, b) => a + b.offerQuantity, 0);

            const bidParser = new Parser({
                fields: [
                    "code",
                    "low",
                    "bidQuantity"
                ],
                header: false
            });

            const offerParser = new Parser({
                fields: [
                    "code",
                    "high",
                    "offerQuantity"
                ],
                header: false
            });

            const content =
                `Exported from NASDAQ Market Replay on ${DateTime.now().toISODate()} at ${DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE)} - http://marketreplay.nasdaq.com/

Order Book for ${replay.details.symbol} on ${replay.details.start.startOf('day').toISODate()} at ${Duration.fromObject({ milliseconds: currentTime }).toISOTime()}
    
Bids
    
Exchange,Bid Price,Bid Quantity
NBBO,${bestBid.low},${nbboBidQuantity},
${bidParser.parse(mostRecentBids.map(q => ({ ...q, code: marketCenters.find(mc => mc.code === q.mc)!.mic })))}

Offers
    
Exchange,Offer Price,Offer Quantity
NBBO,${bestOffer.high},${nbboOfferQuantity},
${offerParser.parse(mostRecentOffers.map(q => ({ ...q, code: marketCenters.find(mc => mc.code === q.mc)!.mic })))}
`

            downloadFile(content, fileName);
        }

    }

    return [enabled, exportQuotes];
}

export default useExportBidsAndOffers;