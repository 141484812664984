import { PureComponent } from "react";
import FormItem, { FieldType } from "../form-item";
import { ListItem } from "@nef/core";
import Downshift, { ControllerStateAndHelpers } from "downshift";
import "./symbol-field.css";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, AppStore } from "../../store";
import { fetchSymbols } from "../../store/refDataSlice";
import Symbol from "../../types/Symbol";

const connector = connect((state: AppStore) => ({
    symbols: state.refData.symbols,
    symbolsStatus: state.refData.symbolsStatus
}), (dispatch: AppDispatch) => ({
    fetchSymbols: async () => {
        await dispatch(fetchSymbols());
    }
}));

type SymbolFieldProps = ConnectedProps<typeof connector> & {
    name: string;
    onUpdate: (val: string) => void;
    initialValue: string;
}

interface SymbolFieldState {
    suggestions: Symbol[],
}

class SymbolField extends PureComponent<SymbolFieldProps, SymbolFieldState> {
    constructor(props: SymbolFieldProps) {
        super(props);

        this.state = {
            suggestions: [],
        };

        this.handleLookup = this.handleLookup.bind(this);
    }

    async componentDidMount() {
        if (this.props.symbolsStatus === "idle") {
            await this.props.fetchSymbols();
        }
    }

    async handleLookup(value: string) {
        this.props.onUpdate(value);

        const formattedValue = (value ?? "").toUpperCase().trim();

        const isMatch = (lookup: Symbol) => (
            lookup.symbol.includes(formattedValue) || lookup.name.includes(formattedValue)
        );

        this.setState({
            suggestions: this.props.symbols.filter(isMatch).slice(0, 15)
        })
    }

    render() {
        return (
            <Downshift
                itemToString={(item) => item?.symbol ?? this.props.initialValue}
                onInputValueChange={this.handleLookup}
                initialInputValue={this.props.initialValue}
                onChange={(selected: Symbol | null) => {
                    this.props.onUpdate(selected?.symbol ?? this.props.initialValue)
                }}
                onOuterClick={(stateAndHelpers: ControllerStateAndHelpers<Symbol>) => {
                    this.props.onUpdate(stateAndHelpers.inputValue ?? "");   
                }}
            >
                {({ isOpen,
                    getMenuProps,
                    getInputProps,
                    getItemProps,
                    highlightedIndex
                }) => (
                    <div className="autocomplete__wrapper">
                        <div>
                            <FormItem
                                downShiftProps={getInputProps()}
                                name={this.props.name}
                                type={FieldType.Text}
                                className="autocomplete__input"
                            />
                        </div>
                        <div className={"autocomplete__menu" + (isOpen ? " autocomplete__menu--open" : "")} {...getMenuProps()}>
                            {isOpen && (
                                this.props.symbolsStatus !== "success"
                                    ? <div>Loading symbols...</div>
                                    : (
                                        this.state.suggestions.length > 0 ?
                                        this.state.suggestions.map((item, index) => {
                                            const { ref, ...props } = getItemProps({ item, index });

                                            return (
                                                <ListItem
                                                    className={
                                                        "autocomplete__option" +
                                                        (highlightedIndex === index ? " autocomplete__option--highlighted" : "")
                                                    } 
                                                    key={`${item.symbol}`}
                                                    title={item.displayName}
                                                    subtitle={item.symbol}
                                                    {...props}
                                                />);

                                                
                                        })
                                        : <div>No matching symbols...</div>
                                    )
                            )}
                        </div>
                    </div>
                )}
            </Downshift>
        )
    }
}

export default connector(SymbolField);
