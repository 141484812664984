import { Button } from '@nef/core';
import useExportAnalysisRegionTradeSummary from '../../utils/useExportAnalysisRegionTradesSummary copy';

const ExportAnalysisRegionTradeSummary = () => {

    const [enabled, exportAnalysisRegionTradeSummary] = useExportAnalysisRegionTradeSummary();

    return (
        <Button
            onClick={exportAnalysisRegionTradeSummary}
            color="secondary"
            outline size="sm"
            disabled={!enabled}>
            Export
        </Button>
    );
}

export default ExportAnalysisRegionTradeSummary