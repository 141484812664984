import { Box, Button, Flex } from "@nef/core";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedReplays, openReplay, deleteSelectedReplays } from "../../store/requestsSlice";
import getEmailContent from "./emailContent";

const ReplayListButtons = () => {
    const dispatch = useDispatch();
    const selectedRequests = useSelector(getSelectedReplays);

    const onOpen = useCallback(() => {
        if (selectedRequests.length !== 1 || selectedRequests[0].status !== 'success') return;
        const replay = selectedRequests[0];
        dispatch(openReplay(replay));
    }, [dispatch, selectedRequests]);

    const onSend = useCallback(() => {
        if (selectedRequests.length !== 1) return;
        const replay = selectedRequests[0]

        const emailBody = getEmailContent(replay);

        window.location.href = "mailto:?subject=Nasdaq Market Replay&body=" + emailBody;
    }, [selectedRequests]);

    const onDelete = useCallback(() => {
        if (selectedRequests.length === 0) return;
        dispatch(deleteSelectedReplays());
    }, [dispatch, selectedRequests]);

    return (
        <Flex justifyContent="between">
            <Flex>
                <Box marginRight={3}>
                    <Button onClick={onOpen} disabled={selectedRequests.length !== 1 || selectedRequests[0].status !== 'success'}>Open</Button>
                </Box>
                <Box marginRight={3}>
                    <Button onClick={onSend} disabled={selectedRequests.length !== 1} outline>Send</Button>
                </Box>
            </Flex>
            <Button onClick={onDelete} disabled={selectedRequests.length === 0} outline>Delete</Button>
        </Flex>
    )
}

export default ReplayListButtons;
