import { Button } from '@nef/core';
import useExportBidsAndOffers from '../../utils/useExportBidsAndOffers';

const ExportBidsAndOffers = () => {

    const [enabled, exportBidsAndOffers] = useExportBidsAndOffers();

    return (
        <Button
            onClick={exportBidsAndOffers}
            color="secondary"
            outline size="sm"
            disabled={!enabled}>
            Export
        </Button>
    );
}

export default ExportBidsAndOffers