import fileDownload from "js-file-download";

export async function printCharts(top: any, bottom: any, filename: string) {
    if (!/.*\.png/.test(filename)) {
        throw new Error("File name must be a valid `.png`");
    }

    let topSvg = top.children[0];
    let bottomSvg = bottom?.children[0];

    const combinedHeight = getSvgHeight(topSvg) + getSvgHeight(bottomSvg);
    const width = topSvg.attributes.width.value;

    console.log(combinedHeight);
    const png = await svgToPng([ topSvg, bottomSvg ], width, combinedHeight);

    console.log("got png");
    fileDownload(png, filename, "image/png");
}

function svgToPng(svgs: any[], width: number, height: number): Promise<Blob> {
    const validSvgs = svgs.filter(svg => svg !== undefined);

    return new Promise((resolve, reject) => {

        const canvas = createCanvas(height, width);
        let context = createContext(canvas, height, width);

        let imgs = [];

        for (let i = 0; i < validSvgs.length; i++) {
            const svg = validSvgs[i];

            if (!svg) continue;

            console.log("svg " + i);
            const yOffset: number = validSvgs.slice(0, i).reduce((offset, current) => getSvgHeight(current) + offset, 0);

            let img = new Image(width, height);

            img.onload = () => {
                context?.drawImage(img, 0, yOffset);

                imgs.push(img);
                tryResolve();
            };

            img.onerror = reject;
            img.src = createImgSrc(svg);

        }

        function tryResolve() {
            if (imgs.length === validSvgs.length) {
                canvas.toBlob(blob => {
                    if (blob) resolve(blob);
                    else reject();
                });
            }
        }
    });
};

function getSvgHeight(svg: any) {
    const heightAttr = svg?.attributes?.height?.value;

    return heightAttr ? Number(heightAttr) : 0;
}

function createCanvas(height: number, width: number) {
    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    return canvas;
}

function createContext(canvas: HTMLCanvasElement, height: number, width: number) {
    let ctx = canvas.getContext('2d');
    ctx!.fillStyle = '#ffffff';
    ctx!.fillRect(0, 0, width, height);

    return ctx;
}

function createImgSrc(svg: any): string {
    let xml = new XMLSerializer().serializeToString(svg);
    return 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
}
