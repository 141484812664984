import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAnalysisRegion, TimeRange, setAnalysisRegion } from "../../store/viewsSlice";
import "./timespan-display.css";
import { getOpenReplay, Replay } from "../../store/requestsSlice";
import TimespanField from "./TimespanField";
import { Duration } from 'luxon';

const getTimespan = (analysisRegion: TimeRange | null, request: Replay | null) => {
    if (!request) return { startTime: undefined, endTime: undefined };

    let startTime = request.details.start;
    let endTime = request.details.end;

    if (analysisRegion) {
        startTime = startTime
            .startOf("day")
            .plus(Duration.fromObject({milliseconds: analysisRegion.startTime}));

        endTime = endTime
            .startOf("day")
            .plus(Duration.fromObject({milliseconds: analysisRegion.endTime}));
    }

    return {
        startTime: startTime.toFormat("HH:mm:ss.SSS"),
        endTime: endTime.toFormat("HH:mm:ss.SSS")
    };
}

const TimespanDisplay = () => {
    const dispatch = useDispatch();

    const analysisRegion: TimeRange | null = useSelector(getAnalysisRegion);
    const currentRequest = useSelector(getOpenReplay, shallowEqual);

    const { startTime, endTime } = getTimespan(analysisRegion, currentRequest);

    const handleStartUpdate = (newStartMs: number) => {
        const startOfDay = currentRequest!.details.start.startOf("day");
        const localStart = currentRequest!.details.start; 
        const localEnd = currentRequest!.details.end;

        const minMs = localStart.diff(startOfDay, "milliseconds").toMillis();
        const end = analysisRegion?.endTime ?? localEnd.diff(startOfDay, "milliseconds").toMillis();

        if (newStartMs >= end || newStartMs < minMs) {
            return;
        }

        const region = {
            startTime: newStartMs,
            endTime: end
        }

        dispatch(setAnalysisRegion({
            region
        }))     
    }

    const handleEndUpdate = (newEndMs: number) => {
        const startOfDay = currentRequest!.details.start.startOf("day");
        const localStart = currentRequest!.details.start; 
        const localEnd = currentRequest!.details.end;

        const start = analysisRegion?.startTime ?? localStart.diff(startOfDay, "milliseconds").toMillis();
        const maxMs = localEnd.diff(startOfDay, "milliseconds").toMillis();

        if (newEndMs > maxMs || newEndMs <= start) {
            return;
        }

        const region = {
            startTime: start,
            endTime: newEndMs
        }

        dispatch(setAnalysisRegion({
            region
        }))     
    }
    
    return (
        <div className="timespan-display">
            <TimespanField
                value={startTime}
                disabled={!currentRequest}
                onValidInput={handleStartUpdate}
            />
            <span className="timespan-display__divider">-</span>
            <TimespanField
                value={endTime}
                disabled={!currentRequest}
                onValidInput={handleEndUpdate}
            />
        </div>
    )
}


export default TimespanDisplay;
