import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FontAwesomeIcon } from "@nef/core"
import useExportQuotes from "../../utils/useExportQuotes";
import useExportTrades from "../../utils/useExportTrades";
import useToggle from "../../utils/useToggle";

const ChartExport = () => {

    const [isOpen, toggle] = useToggle(false);

    const [ exportQuotesEnabled, exportQuotes ] = useExportQuotes();
    const [ exportTradesEnabled, exportTrades ] = useExportTrades();

    return (
        <Dropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle ghost title="download">
                <FontAwesomeIcon iconClassName="fa-download" />
            </DropdownToggle>
            <DropdownMenu maxContent>
                <DropdownItem type="header">Export</DropdownItem>
                <DropdownItem disabled={!exportQuotesEnabled} onClick={exportQuotes}>
                    Export Quotes
                </DropdownItem>
                <DropdownItem disabled={!exportTradesEnabled} onClick={exportTrades}>
                    Export Trades
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default ChartExport;
