import React from 'react';
import { NavbarAvatar, NavbarDropDownContent, NavbarIconContainer, NavbarLink } from '@nef/core';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, logOut } from '../../store/session';

const AuthNav = () => {
    
    const dispatch = useDispatch();
    const session = useSelector(getSession);

    if (!session.isLoggedIn) {
        return <></>;
    }

    return (
        <NavbarIconContainer href="#">
            <NavbarAvatar type="text">{session.username[0].toUpperCase()}</NavbarAvatar>
            <NavbarDropDownContent>
                <NavbarLink
                onClick={() => dispatch(logOut())}>
                    Sign Out
                </NavbarLink>
            </NavbarDropDownContent>                
        </NavbarIconContainer>
    );
}

export default AuthNav;