import { useSelector } from "react-redux";
import { ReferenceLine } from "recharts";
import { getCurrentTime } from "../../store/viewsSlice";

const CustomReferenceLine = (props : any) => {
    const currentTime = useSelector(getCurrentTime);
    return <ReferenceLine {...props} x={currentTime} />;
};

CustomReferenceLine.displayName = "ReferenceLine";
CustomReferenceLine.defaultProps = {
    isFront: false,
    ifOverflow: 'discard',
    xAxisId: 0,
    yAxisId: 0,
    fill: 'none',
    stroke: '#ccc',
    fillOpacity: 1,
    strokeWidth: 1,
    position: 'middle',
  };

  export default CustomReferenceLine;