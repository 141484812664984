export function trackEvent(parameters : any[]) {
    if (typeof window === 'undefined') {
        return;
    }

    if (!(window as any)._paq) {
        return;
    }

    if (typeof (window as any)._paq.push !== 'function') {
        return;
    }

    (window as any)._paq.push(['trackEvent', ...parameters]);
}