// Map each redux action to a beacon event, extracting any relevant data.
// Category, Action, Name (optional), Value
// Where
// - Category: A group of events.
// - Action: An action in that category. 
// - Name: Optionally, the ID of the entity affected by the action.
// - Value: Optionally, a value associated with the action.
export const eventsMap = {

  // Session

  'session/checkSession/pending': () => ['session', 'check-session-pending'],
  'session/checkSession/rejected': () => ['session', 'check-session-rejected'],
  'session/checkSession/fulfilled': (e: any) => ['session', 'check-session-fulfilled', `user: ${e.payload.username}`],

  'session/logIn/pending': (e: any) => ['session', 'log-in-pending', `user: ${e.meta.arg.username}`],
  'session/logIn/rejected': () => ['session', 'log-in-rejected'],
  'session/logIn/fulfilled': (e: any) => ['session', 'log-in-fulfilled', `user: ${e.payload.username}`],

  'session/logOut/pending': () => ['session', 'log-out-pending'],
  'session/logOut/rejected': () => ['session', 'log-out-rejected'],
  'session/logOut/fulfilled': () => ['session', 'log-out-fulfilled'],

  // Requests

  'requests/load/fulfilled': (e: any) => ['replays', 'loaded-replays', '', e.payload.length],
  'requests/addReplayInternal': (e: any) => ['replays', 'add-replay', `replay: ${e.payload.id}`],
  'requests/selectReplay': (e: any) => ['replays', 'open-replay', `replay: ${e.payload}`],
  'requests/openReplay/pending': (e: any) => ['replays', 'open-replay', `replay: ${e.meta.arg.id}`],
  'requests/closeReplay': () => ['replays', 'close-replay'],
  'requests/deleteSelectedReplaysInternal': () => ['replays', 'delete-replays'],

  // Navigation

  'playback/setModeInternal': (e: any) => ['view', 'set-playback-mode', '', e.payload],
  'playback/setSpeedInternal': (e: any) => ['view', 'set-playback-speed', '', e.payload],
  'views/setCurrentTime': () => ['view', 'set-current-time'],
  'views/zoomIn': () => ['view', 'zoom-in'],
  'views/zoomOut': () => ['view', 'zoom-out'],
  'views/pan': () => ['view', 'pan'],
  'views/setAnalysisRegion': () => ['view', 'set-analysis-region'],
  'views/filterMode': (e: any) => ['view', 'set-filter-mode', '', e.payload],
  'views/setIncludedMarketCenters': (e: any) => ['view', 'set-market-centers', '', e.payload.length]

};
