import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppStore } from ".";

interface SessionState {
    inProgress: boolean,
    isLoggedIn: boolean,
    username: string,
    expiresAt: Date | null
}

export interface LogInRequest {
    username: string;
    password: string;
    callback: () => void;
}

const initialState: SessionState = {
    inProgress: false,
    isLoggedIn: false,
    username: '',
    expiresAt: null
};

const setInProgressReducer = (state: SessionState) => { state.inProgress = true };

export const checkSession = createAsyncThunk(
    "session/checkSession",
    async () => {

        const response = await fetch(
            '/me',
            {
                method: 'get',
                headers: { 'Content-Type': 'application/json', credentials: 'include' }
            }
        );
            
        return await response.json();
    }
)

export const logIn = createAsyncThunk(
    "session/logIn",
    async (request: LogInRequest) => {

        const response = await fetch(
            '/login',
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json', credentials: 'include' },
                body: JSON.stringify(request)
            }
        );

        if (request.callback) request.callback();

        return await response.json();
    }
)

export const logOut = createAsyncThunk(
    "session/logOut",
    async () => {

        const response = await fetch(
            '/logout',
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json', credentials: 'include' }
            }
        );
            
        return await response.json();
    }
)

export const slice = createSlice({
    name: "session",
    initialState: initialState,
    reducers: {
        checkSession: setInProgressReducer,
        logIn: setInProgressReducer,
        logOut: setInProgressReducer
    },
    extraReducers: (builder) => {
        builder.addCase(checkSession.fulfilled, (state, action) => {
            state.inProgress = false;
            state.isLoggedIn = action.payload.loggedIn;
            state.username = action.payload.username;
            state.expiresAt = action.payload.expiresAt;
        });
        builder.addCase(logIn.fulfilled, (state, action) => {
            state.inProgress = false;
            state.isLoggedIn = action.payload.loggedIn;
            state.username = action.payload.username;
            state.expiresAt = action.payload.expiresAt;
        });
        builder.addCase(logOut.fulfilled, (state, action) => {
            state.inProgress = false;
            state.isLoggedIn = action.payload.loggedIn;
            state.username = action.payload.username;
            state.expiresAt = action.payload.expiresAt;
        });
    }

})

export const getSession = (state : AppStore) => state.session;

export default slice.reducer;
