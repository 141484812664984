import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Symbol from "../types/Symbol";

export interface RefDataState {
    symbols: Symbol[];
    symbolsStatus: "idle" | "fetching" | "success" | "error";
}

const initialState: RefDataState = {
    symbols: [],
    symbolsStatus: "idle"
};

export const fetchSymbols = createAsyncThunk(
    "refData/fetchSymbols",
    async () => {
        const url = "/symbols";

        console.debug("Retrieving symbols", url);

        const response = await fetch(url);

        if (!response.ok) {
            throw Error("Could not retrieve symbols");
        }

        const txt = await response.text();
        const allLines = txt.split("\n");
        const lines = allLines.slice(1, allLines.length - 2); // Skip header line and footer line.
        const symbols = lines.map(line => {
            const segments = line.split("|");

            return {
                symbol: segments[1].toUpperCase(),
                name: segments[2].replace(/,\./g, "").toUpperCase(),
                displayName: segments[2]
            } as Symbol;
        });

        return symbols.filter(s => s.name && s.symbol);
    }
)

export const slice = createSlice({
    name: "refData",
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSymbols.fulfilled, (state: RefDataState, action: PayloadAction<Symbol[]>) => {
            state.symbols = action.payload;
            state.symbolsStatus = "success"
        });

        builder.addCase(fetchSymbols.pending, (state: RefDataState) => {
            state.symbolsStatus = "fetching"
        });

        builder.addCase(fetchSymbols.rejected, (state: RefDataState) => {
            state.symbolsStatus = "error"
        });
    }
})

export default slice.reducer;
