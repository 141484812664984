import { useSelector } from "react-redux";
import { getQuotes } from "../store/quotesSlice";
import { getOpenReplay, Replay } from "../store/requestsSlice";
import downloadFile from "js-file-download";
import { useMatomo } from "./matomo";
import CsvWorker from '../workers/csv-worker';

const csvWorker = new CsvWorker();

const getFileName = (replay: Replay) => {

    const date = replay.details.start.toISODate();

    return `${replay.details.symbol}-${date}-Quotes.csv`;
}

const useExportQuotes: () => [boolean, () => void] = () => {

    const replay = useSelector(getOpenReplay);
    const quotes = useSelector(getQuotes);
    const [trackEvent] = useMatomo();

    const enabled = replay !== null && replay.details.hasQuotes;

    const exportQuotes = () => {
        if (enabled && replay) {

            trackEvent(['export', 'export-quotes', `replay: ${replay.id}`, quotes.length]);

            const fileName = getFileName(replay);

            const startDate = replay.details.start;

            const startMs = startDate.startOf("day").toMillis();

            csvWorker.convertQuotesToCsv(startMs, quotes)
                .then(csv => downloadFile(csv, fileName))
                .catch(err => console.error(err));
        }
    };

    return [enabled, exportQuotes];
}

export default useExportQuotes;