import { findMaxIndex } from "./binarySearch";

// Partitions a sorted data set by a specified key, preserving the order.
export function partition<T>(
    data: T[],
    getKey: (item: T) => string,
) {
    const dict: { [key: string]: T[] } = {};
    for (let index = 0; index < data.length; index++) {
        const key: string = getKey(data[index]);
        if (key in dict) {
            dict[key].push(data[index]);
        } else {
            dict[key] = [data[index]];
        }
    }
    return dict;
}

// Find the most recent item containing the given time.
export function findMostRecent<T>(
    data: T[],
    startFunc: (item: T) => number,
    endFunc: (item: T) => number,
    currentTime: number
) {
    const index = findMaxIndex(data, startFunc, currentTime);

    if (index === -1) return null;

    return (endFunc(data[index]) >= currentTime) ? data[index] : null;
}


