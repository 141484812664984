import { useSelector } from "react-redux";
import { getOpenReplay, getOpenReplayIsLoading } from "../store/requestsSlice";
import ReplayPage from "./ReplayPage";
import ReplayListPage from "./ReplayListPage";

const Body = () => {

    const replay = useSelector(getOpenReplay);
    const loading = useSelector(getOpenReplayIsLoading);

    if (!replay || loading) {
        return <ReplayListPage />
    } else {
        return <ReplayPage />
    }
};

export default Body;
