import { Parser } from "json2csv";
import { useSelector } from "react-redux";
import { getExchangeInfoInAnalysisRegion } from "../store/quotesSlice";
import { getOpenReplay, Replay } from "../store/requestsSlice";
import downloadFile from "js-file-download";
import { useMatomo } from "./matomo";

const getFileName = (replay: Replay) => {

    const date = replay.details.start.toISODate();

    return `${replay.details.symbol}-${date}-QuoteSummary.csv`;
}

const useExportAnalysisRegionQuoteSummary: () => [boolean, () => void] = () => {

    const replay = useSelector(getOpenReplay);
    const exchanges = useSelector(getExchangeInfoInAnalysisRegion);
    const [trackEvent] = useMatomo();

    const enabled = replay !== null && replay.details.hasQuotes;

    const exportQuotes = () => {
        if (enabled && replay) {

            trackEvent(['export', 'export-analysis-region-quote-summary', `replay: ${replay.id}`]);

            const fileName = replay ? getFileName(replay) : '';

            const parser = new Parser({
                fields: [
                    "mc",
                    "lowBid",
                    "highBid",
                    "lowAsk",
                    "highAsk"
                ]
            });

            downloadFile(
                parser.parse(exchanges),
                fileName
            );
        }
    };

    return [enabled, exportQuotes];
}

export default useExportAnalysisRegionQuoteSummary;