import { Field, useFormikContext } from "formik";
import React from "react";
import { FormField, FormFieldWithIcon } from "@nef/core";
import "./FormItem.css";

export enum FieldType {
    Text = "text",
    Date = "date",
    Time = "time",
    Password = "password"
}

interface FormItemProps extends React.ComponentProps<typeof FormField> {
    name: string;
    type: FieldType;
    placeholder?: string;
    disabled?: boolean;
    pattern?: string;
    icon?: string;
    downShiftProps?: any;
}

const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1);

const FormItem = ({
    name,
    placeholder,
    type = FieldType.Text,
    disabled = false,
    downShiftProps,
    as,
    icon,
    label
}: FormItemProps) => {

    const { errors, touched } = useFormikContext() as any;

    return (
        <div className="form__item">
            { as
                ? (as)
                : (
                    <Field
                        as={icon ? FormFieldWithIcon : FormField}
                        name={name}
                        id={name}
                        type={type}
                        size="md"
                        label={label ?? capitalize(name)}
                        placeholder={placeholder}
                        disabled={disabled}
                        className="form__item__input"
                        feedback={errors[name] && touched[name] ? errors[name] : null}
                        invalid={errors[name] && touched[name]}
                        iconClassName={icon}
                        addonType={icon ? "prepend" : ""}
                        {...downShiftProps}
                    />
                )
            }
        </div>
    )
}

export default FormItem;