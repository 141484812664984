import React, { Component } from "react";
import { ReferenceLine } from "recharts";
import { QuoteLineContextConsumer } from "./context/QuoteLineContext";

export default class QuoteChangeLines extends Component {
    static displayName = "ReferenceArea";
    static defaultProps = {
        isFront: false,
        ifOverflow: 'discard',
        xAxisId: 0,
        yAxisId: 0,
        r: 10,
        fill: '#ccc',
        fillOpacity: 0.5,
        stroke: 'none',
        strokeWidth: 1,
      };

    render() {
        return (
            <QuoteLineContextConsumer>
                {
                    (changeTimes) => {
                        return changeTimes.map(time => (
                            <ReferenceLine
                                {...this.props}
                                key={time}
                                x={time}
                                strokeWidth={1}
                                stroke="#7777aa"
                                className="quote-change-line"
                                ifOverflow="discard"
                            />
                        ))
                    }
                }
            </QuoteLineContextConsumer>
        )
    }
}
