import { Component } from "react";
import { ReferenceArea } from "recharts";
import { ZoomRegionContext } from "./ChartContextProvider";

class ZoomRegion extends Component {
    static displayName = 'ReferenceArea';
    static defaultProps = {
        isFront: false,
        ifOverflow: 'discard',
        xAxisId: 0,
        yAxisId: 0,
        r: 10,
        fill: '#ccc',
        fillOpacity: 0.5,
        stroke: 'none',
        strokeWidth: 1,
      };
      
    render() {
        return (
            <ZoomRegionContext.Consumer>
                {(context) => {
                    const visible = context.mode === 'inProgress';
                    return (
                        visible && <ReferenceArea
                            {...this.props}
                            x1={context.startTime}
                            x2={context.endTime} />
                    )}
                }
            </ZoomRegionContext.Consumer>
        );
    }
}

export default ZoomRegion