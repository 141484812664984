import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RichTable } from "@nef/lab";
import MarketCenter from "../market-center";
import Currency from "../currency";
import { getExchangeInfoInAnalysisRegion } from "../../store/quotesSlice";

const columns = [
    {
        accessor: "mc",
        Header: "MC",
        Cell: ({ value }: any) => <MarketCenter code={value} />,
        width: 75
    },
    {
        accessor: "lowBid",
        Header: "Low Bid",
        isNumeric: true,
        Cell: ({ value }: any) => <Currency amount={value} />,
        width: 70
    },
    {
        accessor: "highBid",
        Header: "High Bid",
        isNumeric: true,
        Cell: ({ value }: any) => <Currency amount={value} />,
        width: 70
    },
    {
        accessor: "lowAsk",
        Header: "Low Ask",
        isNumeric: true,
        Cell: ({ value }: any) => <Currency amount={value} />,
        width: 70
    },
    {
        accessor: "highAsk",
        Header: "High Ask",
        isNumeric: true,
        Cell: ({ value }: any) => <Currency amount={value} />,
        width: 70
    }
];

const QuotesTable = () => {

    const data = useSelector(getExchangeInfoInAnalysisRegion);

    const options = useMemo(() => ({
        data: data,
        columns: columns
    }), [data]);

    return (
        <RichTable
            options={options}
            isPaginationEnabled />
    );
}

export default QuotesTable;
