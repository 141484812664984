import { Provider as ReduxProvider } from "react-redux";
import store from "./store";
import { NEFThemeProvider } from "@nef/core"
import AppNav from "./components/app-nav";
import LoginGate from "./components/login-gate";
import Body from "./layout/Body";
import './App.css';

const App = () => {

  return (
    <NEFThemeProvider>
      <ReduxProvider store={store}>
        <AppNav />
        <main>
          <LoginGate>
            <Body />
          </LoginGate>
        </main>
      </ReduxProvider>
    </NEFThemeProvider>
  );
}

export default App;
