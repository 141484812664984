import React, { useCallback, useState } from 'react';
import { Box, Button, Flex, Modal, ModalBody, ModalHeader } from '@nef/core';
import ExchangeFilter from './ExchangeFilter';
import { useDispatch, useSelector } from 'react-redux';
import { getIncludedMarketCenters, setIncludedMarketCenters } from '../../store/viewsSlice';
import { calculateBbo } from '../../store/quotesSlice';

const ExchangeFilterModal = ({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) => {

    const dispatch = useDispatch();
    const initialData = useSelector(getIncludedMarketCenters);
    const [data, setData] = useState(initialData);

    const onChangeCallback = useCallback(data => { 
        setData(data)
    }, [setData]);
    
    const onCancelCallback = useCallback(() => {
        onClose();
    }, [onClose]);

    const onApplyCallback = useCallback(() => {
        dispatch(setIncludedMarketCenters(data));
        dispatch(calculateBbo());
        onClose();
    }, [dispatch, data, onClose]);

    return (
        <Modal size="md" isOpen={isOpen}>
            <ModalHeader>Select Exchanges</ModalHeader>
            <ModalBody>
                <ExchangeFilter initialData={initialData} onChange={onChangeCallback} />
                <Box marginTop={4}>
                    <Flex justifyContent="end">
                        <Box marginLeft={3}>
                            <Button outline onClick={onCancelCallback}>Cancel</Button>
                        </Box>
                        <Box marginLeft={3}>
                            <Button onClick={onApplyCallback}>Apply</Button>
                        </Box>
                    </Flex>
                </Box>
            </ModalBody>
        </Modal>
    );
}

export default ExchangeFilterModal;