import { RichTable } from "@nef/lab";
import { cloneDeep } from "lodash";
import { useState } from "react";
import mcCodes from "../../mcCodes.json";

const columns = [
    {
        Header: "Exchange",
        accessor: "mic",
        width: 80
    },
    {
        Header: "Name",
        accessor: "name",
        width: 150
    },
    {
        Header: "MC",
        accessor: "code",
        width: 50
    }
];

const ExchangeFilter = ({ initialData, onChange }: { initialData: string[], onChange: (data: string[]) => void }) => {

    const filterableCodes = removeUnusedExchanges(mcCodes);
    const combinedCodes = combineDuplicateCodes(filterableCodes);

    const selectedRowIds = {} as Record<string, boolean>;
    if (initialData.length > 0) {
        const combinedInitialData = combineInitialCodes(initialData, combinedCodes.map((c: any) => c.code));

        combinedInitialData.forEach(mc => {
            selectedRowIds[mc] = true;
        });
    }

    const [selectedRows, setSelectedRows] = useState(selectedRowIds);

    return (
        <RichTable
            options={{
                columns: columns,
                data: combinedCodes,
                getRowId: r => r.code,
                initialState: {
                    selectedRowIds: selectedRows
                }
            }}
            bodyHeight={500}
            isRowSelectEnabled
            onRowsSelected={rows => {
                setSelectedRows(rows);

                const includedExchanges = splitDuplicateExchanges(Object.keys(rows));
                
                onChange(includedExchanges.length !== mcCodes.length ? includedExchanges : []);
            }}
        />
    );
}

const combineDuplicateCodes = (codes: any[]) => {
    return codes.reduce((combined, exchange) => {
        const existingIndex = combined.findIndex((exch: any) => exch.name === exchange.name);

        if (existingIndex > -1) {
            let updated = [...combined];
            updated[existingIndex].code = `${updated[existingIndex].code}, ${exchange.code}`;

            return updated;
        } else {
            return [...combined, cloneDeep(exchange)];
        }
    }, [])
}

const splitDuplicateExchanges = (codes: string[]) => {
    return codes.reduce((updated: string[], code: string) => {

        if (code.includes(",")) {
            const split = code.split(",").map(c => c.trim());

            return [...updated, ...split];
        } else {
            return [...updated, code];
        }

    }, []);
}

const removeUnusedExchanges = (exchanges: any[]) => {
    const unusedCodes = ["u", "S"];

    return exchanges.filter(exch => !unusedCodes.includes(exch.code));
}

const combineInitialCodes = (initialData: string[], combinedCodes: string[]) => {
    let result = [];

    for (let code of initialData) {
        const existingIndex = result.findIndex((r: string) => r.includes(code));

        if (existingIndex > -1) {
            continue;
        }

        const match = combinedCodes.find((c: string) => c.includes(code));

        if (match) {
            result.push(match);
        } else {
            result.push(code);
        }
    }

    return result;
}

export { combineDuplicateCodes, splitDuplicateExchanges };
export default ExchangeFilter;