import React from "react";
import { Formik, FormikHelpers, Form, FormikProps } from "formik";
import FormItem, { FieldType } from "../form-item";
import { Button } from "@nef/core";
import { logIn } from "../../store/session";
import { useDispatch } from "react-redux";
import * as yup from "yup";

interface LogInFormData {
    username: string,
    password: string
}

const LoginForm = () => {
    const dispatch = useDispatch();

    const handleSubmit = (values: LogInFormData, { setSubmitting }: FormikHelpers<LogInFormData>) => {
        dispatch(logIn({username: values.username, password: values.password, callback: () => setSubmitting(false)}));
        setSubmitting(true);
    }

    return (
        <div>
            <Formik
                initialValues={process.env.NODE_ENV === 'development' ? {username: 'demo', password: 'secret'} : {username: '', password: ''}}
                validationSchema={LoginFormSchema}
                onSubmit={handleSubmit}
            >
                {({ isValid, isSubmitting }: FormikProps<any>) => (
                    <Form className="form">
                        <FormItem name="username" type={FieldType.Text} icon="fa-user" placeholder="Enter username" />
                        <FormItem name="password" type={FieldType.Password} icon="fa-key" placeholder="Enter password" />
                        <Button
                            type="submit"
                            value="Submit"
                            size="md"
                            block
                            disabled={!isValid || isSubmitting}
                        >
                            Sign in
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const LoginFormSchema: yup.SchemaOf<LogInFormData> = yup.object({
    username: yup.string()
        .label("Username")
        .required(),
    password: yup.string()
        .label("Password")
        .required()
});

export default LoginForm;
