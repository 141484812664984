import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import requestReducer from "./requestsSlice";
import viewsReducer from "./viewsSlice";
import tradesReducer from "./tradesSlice";
import quotesReducer from "./quotesSlice";
import sessionReducer from "./session";
import playbackReducer from "./playbackSlice";
import refDataReducer from "./refDataSlice";
import beaconMiddleware from "./beacon/beaconMiddleware";

const middleware = getDefaultMiddleware({immutableCheck: false, serializableCheck: false});
middleware.push(beaconMiddleware);

const store = configureStore({
    reducer: {
        requests: requestReducer,
        views: viewsReducer,
        trades: tradesReducer,
        quotes: quotesReducer,
        session: sessionReducer,
        playback: playbackReducer,
        refData: refDataReducer
    },
    middleware: middleware
});

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof store.getState>
export default store;
