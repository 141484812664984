import { Section, SectionBody, SectionHeader } from "@nef/core";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import Chart from "../components/chart";
import ChartExport from "../components/chart-export";
import ChartFilters from "../components/chart-filters";
import { getOpenReplay, ReplayDetails } from "../store/requestsSlice";

const getReplayTitle = (replay : ReplayDetails) => {
    return `${replay.symbol} - ${replay.start.toISODate()} - ${replay.start.toLocaleString(DateTime.TIME_24_SIMPLE)} - ${replay.end.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
}

const ChartPanel = () => {

    const replay = useSelector(getOpenReplay);

    return (
        <Section>
            <SectionHeader title={getReplayTitle(replay!.details)}>
                <ChartExport />
                &nbsp;
                <ChartFilters />
            </SectionHeader>
            <SectionBody>
                <div style={{ height: '40vh', overflow: 'hidden' }}>
                    <Chart />
                </div>
            </SectionBody>
        </Section>
    );
}

export default ChartPanel;