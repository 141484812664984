import { sortBy } from 'lodash';
import { Duration } from 'luxon';
import React, { Component, createRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppStore } from '../../store';
import { getTradesInCurrentView, getTradeSummaryInCurrentView, selectTrade } from '../../store/tradesSlice';
import { getBestBidsInCurrentView, getBestOffersInCurrentView, getQuotes } from '../../store/quotesSlice';
import { getAnalysisRegion, getCurrentView, intersectTimeRanges, pan, setAnalysisRegion, setCurrentTime, TimeRange, zoomIn, zoomOut, getFilterMode, FilterMode, tradesVisible, getCanZoomOut } from '../../store/viewsSlice';
import { IAggregatedTrade, IQuote, IQuoteDataPoint, ITrade } from '../../types';
import Currency from '../currency';
import MarketCenter from '../market-center';
import Chart from './Chart';
import QuoteDetails from './QuoteDetails';
import { getOptimalTickGap } from './utils/tickSize';
import { QuoteLineContextProvider } from './context/QuoteLineContext';
import { getChanges } from './utils/quoteChanges';
import { ZoomOutButton, ZoomInButton, PanButton, AnalysisButton, ScreenshotButton } from './ChartButtons';
import { getOpenReplay } from '../../store/requestsSlice';
import { getDateTime } from '../../utils/time';
import { printCharts } from '../../utils/print';

const CLICK_PIXEL_RANGE = 3;

export type ControlMode = 'zoom' | 'pan' | 'analysis';

export type HighlightedRegion = {
    mode: 'none' | 'start' | 'inProgress',
    startTime: number,
    endTime: number
};

export type Pan = {
    mode: 'none' | 'start' | 'inProgress',
    startTime: number
}

const initialZoomRegion: HighlightedRegion = { mode: 'none', startTime: 0, endTime: 0 };
const initialAnalysisRegion: HighlightedRegion = { mode: 'none', startTime: 0, endTime: 0 };
const initialPan: Pan = { mode: 'none', startTime: 0 };

export const ZoomRegionContext = React.createContext(initialZoomRegion);
export const AnalysisRegionContext = React.createContext({ startTime: 0, endTime: 0 } as TimeRange);

type ChartContextProviverProps = ConnectedProps<typeof connector>;

type TopTooltip = {
    display: 'none' | 'trade' | 'bid' | 'offer',
    x: number | null, // X position of data point relative to chart div.
    y: number | null, // Y position of data point relative to chart div.
    dd: number | null, // distance from mouse
    item: ITrade | IQuoteDataPoint<IQuote> | null,
    reverseX: boolean, // If the tooltip is too near the right-edge of the chart, draw it to the left of the data point.
    reverseY: boolean // If the tooltip is too near the bottom-edge of the chart, draw it above the data point.
}

type BottomTooltip = {
    display: boolean,
    x: number | null,
    dd: number | null,
    item: IAggregatedTrade | null
}

type ChartContextProviverState = {
    mode: ControlMode,
    zoomRegion: HighlightedRegion,
    analysisRegion: HighlightedRegion,
    pan: Pan,
    mouseDownX: number | null,
    mouseMoveIntentonal: boolean,
    topTooltip: TopTooltip,
    bottomTooltip: BottomTooltip,
    quoteDetails: IQuote[] | null,
    changeTimes: number[]
};

const noTopTooltip: TopTooltip = {
    display: 'none',
    x: null,
    y: null,
    dd: null,
    item: null,
    reverseX: false,
    reverseY: false
}

const noBottomTooltip: BottomTooltip = {
    display: false,
    x: null,
    dd: null,
    item: null
}

const numberFormatter = new Intl.NumberFormat(
    'en-US',
    {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })

class ChartContextProviver extends Component<ChartContextProviverProps, ChartContextProviverState>
{
    chartRef: any;

    private topChartRef = createRef<HTMLDivElement>();
    private bottomChartRef = createRef<HTMLDivElement>();
    private barChartRef = createRef<HTMLDivElement>();

    constructor(props: any) {
        super(props);
        this.onSetMode = this.onSetMode.bind(this);
        this.onZoomOut = this.onZoomOut.bind(this);
        this.innerRef = this.innerRef.bind(this);

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);

        this.onMouseMoveForTooltip = this.onMouseMoveForTooltip.bind(this);
        this.clearTooltips = this.clearTooltips.bind(this);

        this.clearQuoteDetails = this.clearQuoteDetails.bind(this);
        this.handlePrint = this.handlePrint.bind(this);

        this.chartRef = {};
        this.state = {
            mode: 'zoom',
            zoomRegion: initialZoomRegion,
            analysisRegion: initialAnalysisRegion,
            pan: initialPan,
            mouseDownX: null,
            mouseMoveIntentonal: false,
            topTooltip: noTopTooltip,
            bottomTooltip: noBottomTooltip,
            quoteDetails: null,
            changeTimes: []
        };
    }

    getInitialAnalysisRegion() {
        if (this.props.analysisRegion) {
            return { mode: 'none', startTime: this.props.analysisRegion.startTime, endTime: this.props.analysisRegion.endTime } as HighlightedRegion
        } else {
            return initialAnalysisRegion;
        }
    }

    innerRef(node: any) {
        this.chartRef = node;
    }

    onSetMode(mode: ControlMode) {
        this.setState(s => ({ ...s, mode }));
    }

    onZoomOut() {
        this.props.zoomOut();
    }

    onStart(position: number) {

        this.setState(s => ({ ...s, mouseDownX: position, mouseMoveIntentonal: false }));

        const exactTime = this.getExactTime(position);

        switch (this.state.mode) {
            case 'zoom':
                this.setState(s => ({ ...s, zoomRegion: { mode: 'start', startTime: exactTime, endTime: exactTime } }));
                break;
            case 'pan':
                this.setState(s => ({ ...s, pan: { mode: 'start', startTime: exactTime } }));
                break;
            case 'analysis':
                const roundedTime = Math.round(exactTime);

                this.setState(s => ({ ...s, analysisRegion: { mode: 'start', startTime: roundedTime, endTime: roundedTime } }));
                break;
        }
    }

    onMove(position: number) {
        const exactTime = this.getExactTime(position);

        if (!this.state.mouseMoveIntentonal) {
            if (!this.state.mouseDownX) {
                return;
            }

            const currentX = position;

            const distanceMoved = Math.abs(this.state.mouseDownX - currentX);

            if (distanceMoved < 10) {
                // Need to move at least a few pixels for this to count as a move.
                // This allows "clicking" to be less sensitive to subtle mouse movements.
                return;
            }

            this.setState(s => ({ ...s, mouseMoveIntentonal: true }));
        }

        switch (this.state.mode) {
            case 'zoom':
                if (this.state.zoomRegion.mode !== 'none' && exactTime !== this.state.zoomRegion.endTime) {
                    this.setState(s => ({ ...s, zoomRegion: { mode: 'inProgress', startTime: s.zoomRegion.startTime, endTime: exactTime } }));
                }
                break;
            case 'pan':
                if (this.state.pan.mode === 'start' || this.state.pan.mode === 'inProgress') {
                    const offset = this.state.pan.startTime - exactTime;
                    const newStartTime = this.props.currentView!.startTime + offset;
                    this.setState(s => ({ ...s, pan: { mode: 'inProgress', startTime: exactTime + offset } }));
                    this.props.pan(newStartTime);
                }
                break;
            case 'analysis':
                const roundedTime = Math.round(exactTime);

                if (this.state.analysisRegion.mode === 'start' || this.state.analysisRegion.mode === 'inProgress') {
                    this.setState(s => ({ ...s, analysisRegion: { mode: 'inProgress', startTime: s.analysisRegion.startTime, endTime: roundedTime } }));
                }
                break;
        }
    }

    onEnd() {
        switch (this.state.mode) {
            case 'zoom':
                switch (this.state.zoomRegion.mode) {
                    case 'none':
                        break;
                    case 'start':
                        this.checkForOnClickTargetOrSetCurrentTime(this.state.zoomRegion.endTime);
                        break;
                    case 'inProgress':
                        this.props.zoomIn(this.getZoomRegion());
                        break;
                }
                this.setState(s => ({ ...s, zoomRegion: initialZoomRegion }));
                break;
            case 'pan':
                if (this.state.pan.mode === 'none' || this.state.pan.mode === 'start') {
                    this.checkForOnClickTargetOrSetCurrentTime(this.state.pan.startTime);
                }
                this.setState(s => ({ ...s, pan: initialPan }));
                break;
            case 'analysis':
                switch (this.state.analysisRegion.mode) {
                    case 'none':
                        break;
                    case 'start':
                        if (this.state.topTooltip.display === 'trade') {
                            const trade = this.state.topTooltip.item as ITrade;
                            this.props.selectTrade(trade);
                        } else {
                            this.checkForOnClickTargetOrSetCurrentTime(this.state.analysisRegion.endTime);
                        }
                        break;
                    case 'inProgress':
                        this.props.setAnalysisRegion(this.getAnalysisRegion());
                        break;
                }
                this.setState(s => ({ ...s, analysisRegion: this.getInitialAnalysisRegion() }));
                break;
        }
    }

    checkForOnClickTargetOrSetCurrentTime(time: number) {

        if (this.state.topTooltip.display === 'trade') {
            const trade = this.state.topTooltip.item as ITrade;
            this.props.selectTrade(trade);
            return;
        }

        if (this.displayQuoteLines) {

            const position = this.getPositionFromTime(time);
            const changeTimes = this.changeTimes;

            for (let i = 0; i < changeTimes.length; i++) {
                const changeTime = changeTimes[i];
                const changePosition = this.getPositionFromTime(changeTime);

                if (Math.abs(changePosition - position) <= CLICK_PIXEL_RANGE) {
                    this.updateQuoteDetails(changeTime);
                    return; // something has been clicked, stop loop and exit
                }
            }
        }

        this.props.setCurrentTime(time);
    }

    updateQuoteDetails(time: number) {
        const quotes = sortBy(
            this.props.quotes.filter(quote => quote.start <= time && quote.end > time && (quote.bidQuantity || quote.offerQuantity)),
            q => q.seqnum,
            "asc"
        );

        this.setState({ quoteDetails: quotes });
    }

    clearQuoteDetails() {
        if (this.state.quoteDetails) {
            this.setState({ quoteDetails: null });
        }
    }

    onMouseMoveForTooltip(e: React.MouseEvent) {

        const topChartRect = this.topChartRef.current?.getBoundingClientRect()!;
        const topChartOffset = this.chartRef.state.offset;

        const pageX = e.pageX - window.scrollX;
        const pageY = e.pageY - window.scrollY;

        let topTooltip: TopTooltip = {
            ...noTopTooltip,
            reverseX: pageX > ((topChartRect.left + topChartOffset.left) + (topChartRect.right - topChartOffset.right)) / 2,
            reverseY: pageY > ((topChartRect.top + topChartOffset.top) + (topChartRect.bottom + topChartOffset.bottom)) / 2
        };

        if (pageX >= (topChartRect.left + topChartOffset.left) &&
            pageX <= (topChartRect.right - topChartOffset.right) &&
            pageY >= (topChartRect.top + topChartOffset.top) &&
            pageY <= (topChartRect.bottom - topChartOffset.bottom)) {

            // Mouse is somewhere over the top chart.
            const dist = 10;
            const minTime = this.getExactTime(Math.max(pageX - dist, (topChartRect.left + topChartOffset.left)));
            const maxTime = this.getExactTime(Math.min(pageX + dist, (topChartRect.right - topChartOffset.right)));
            const maxPrice = this.getExactPrice(Math.max(pageY - dist, (topChartRect.top + topChartOffset.top)));
            const minPrice = this.getExactPrice(Math.min(pageY + dist, (topChartRect.bottom - topChartOffset.bottom)));
            const { filterMode } = this.props;

            if (filterMode !== FilterMode.Quotes) {
                this.props.trades.forEach(t => {
                    if (t.timestamp >= minTime && t.timestamp <= maxTime && t.price >= minPrice && t.price <= maxPrice) {
                        const x = this.getPositionFromTime(t.timestamp);
                        const y = this.getPositionFromPrice(t.price);

                        const dd = (x - pageX) * (x - pageX) +
                            (y - pageY) * (y - pageY);

                        if (dd < dist * dist) {
                            if (!topTooltip.dd || dd < topTooltip.dd) {
                                topTooltip.display = 'trade';
                                topTooltip.dd = dd;
                                topTooltip.x = x - topChartRect.left;
                                topTooltip.y = y - topChartRect.top;
                                topTooltip.item = t;
                            }
                        }
                    }
                })
            }

            if (filterMode !== FilterMode.Trades) {

                this.props.bids.forEach(b => {
                    if (b.value && b.time >= minTime && b.time <= maxTime && b.value >= minPrice && b.value <= maxPrice) {
                        const x = this.getPositionFromTime(b.time);
                        const y = this.getPositionFromPrice(b.value);

                        const dd = (x - pageX) * (x - pageX) +
                            (y - pageY) * (y - pageY);

                        if (dd < dist * dist) {
                            if (!topTooltip.dd || dd < topTooltip.dd) {
                                topTooltip.display = 'bid';
                                topTooltip.dd = dd;
                                topTooltip.x = x - topChartRect.left;
                                topTooltip.y = y - topChartRect.top;
                                topTooltip.item = b;
                            }
                        }
                    }
                })

                this.props.offers.forEach(o => {
                    if (o.value && o.time >= minTime && o.time <= maxTime && o.value >= minPrice && o.value <= maxPrice) {
                        const x = this.getPositionFromTime(o.time);
                        const y = this.getPositionFromPrice(o.value);

                        const dd = (x - pageX) * (x - pageX) +
                            (y - pageY) * (y - pageY);

                        if (dd < dist * dist) {
                            if (!topTooltip.dd || dd < topTooltip.dd) {
                                topTooltip.display = 'offer';
                                topTooltip.dd = dd;
                                topTooltip.x = x - topChartRect.left;
                                topTooltip.y = y - topChartRect.top;
                                topTooltip.item = o;
                            }
                        }
                    }
                })
            }
        }

        if (this.state.topTooltip.item !== topTooltip.item) {
            this.setState(s => ({ ...s, topTooltip: topTooltip }));
        }

        if (!this.props.tradesVisible) {
            return;
        }

        const bottomChartRect = this.bottomChartRef.current?.getBoundingClientRect()!;
        const bottomChartOffset = this.chartRef.state.offset;

        let bottomTooltip: BottomTooltip = { ...noBottomTooltip };

        if (pageX >= (bottomChartRect.left + bottomChartOffset.left) &&
            pageX <= (bottomChartRect.right - bottomChartOffset.right) &&
            pageY >= (bottomChartRect.top + bottomChartOffset.top) &&
            pageY <= (bottomChartRect.bottom - bottomChartOffset.bottom)) {

            // Mouse is somewhere over the bottom chart.
            this.props.aggregatedTrades.forEach(t => {
                const x1 = this.getPositionFromTime(t.startTime);
                const x2 = this.getPositionFromTime(t.endTime);
                const x = (x1 + x2) / 2;
                {
                    const dd = (x - pageX) * (x - pageX);

                    if (!bottomTooltip.dd || dd < bottomTooltip.dd) {
                        bottomTooltip.display = true;
                        bottomTooltip.dd = dd;
                        bottomTooltip.x = x - bottomChartRect.left;
                        bottomTooltip.item = t;
                    }
                }
            })
        }

        if (this.state.bottomTooltip.item !== bottomTooltip.item) {
            this.setState(s => ({ ...s, bottomTooltip }));
        }
    }

    clearTooltips() {
        this.setState(s => ({ ...s, topTooltip: noTopTooltip, bottomTooltip: noBottomTooltip }));
    }

    getTimeFromPosition(position: number) {
        const { left } = this.chartRef.this.state.offset;
        const [minVal] = this.chartRef.state.xAxisMap[0].domain;
        const chartOffset = this.getTopChartXOffset();
        const timePerPixel = this.getTimePerPixel();

        return ((position - chartOffset - left) * timePerPixel) + minVal;
    }

    getPositionFromTime(time: number): number {
        const { left } = this.chartRef.state.offset;
        const [minVal] = this.chartRef.state.xAxisMap[0].domain;
        const timePerPixel = this.getTimePerPixel();
        const chartOffset = this.getTopChartXOffset();
        return ((time - minVal) / timePerPixel) + left + chartOffset;
    }

    getTimePerPixel() {
        const { width } = this.chartRef.state.offset;
        const [minVal, maxVal] = this.chartRef.state.xAxisMap[0].domain;

        return (maxVal - minVal) / width;
    }

    getTopChartXOffset() {
        return this.topChartRef.current?.getBoundingClientRect().x ?? 1;
    }

    getPositionFromPrice(price: number): number {
        const { height, top } = this.chartRef.state.offset;
        const [minVal, maxVal] = this.chartRef.state.yAxisMap[0].domain;
        const pricePerPixel = (maxVal - minVal) / height;
        const chartOffset = this.topChartRef.current?.getBoundingClientRect().top;
        return ((maxVal - price) / pricePerPixel) + top + chartOffset;
    }

    getExactTime(position: number): number {
        const { width, left } = this.chartRef.state.offset;
        const [minVal, maxVal] = this.chartRef.state.xAxisMap[0].domain;

        const timePerPixel = (maxVal - minVal) / width;

        const chartOffset = this.topChartRef.current?.getBoundingClientRect().x;

        return ((position - (chartOffset + left)) * timePerPixel) + minVal;
    }

    getExactPrice(position: number): number {
        const { height, top } = this.chartRef.state.offset;
        const [minVal, maxVal] = this.chartRef.state.yAxisMap[0].domain;

        const pricePerPixel = (maxVal - minVal) / height;

        const chartOffset = this.topChartRef.current?.getBoundingClientRect().top;

        return maxVal - ((position - (chartOffset + top)) * pricePerPixel);
    }

    getAnalysisRegion() {
        if (this.state.analysisRegion.mode !== 'none') {
            const startTime = Math.min(this.state.analysisRegion.startTime, this.state.analysisRegion.endTime);
            const endTime = Math.max(this.state.analysisRegion.startTime, this.state.analysisRegion.endTime);
            return { startTime, endTime };
        } else if (this.props.analysisRegion) {
            return ({ mode: 'none', startTime: this.props.analysisRegion.startTime, endTime: this.props.analysisRegion.endTime } as HighlightedRegion);
        } else {
            return initialAnalysisRegion;
        }
    }

    getDisplayAnalysisRegion() {
        const analysisRegion = this.getAnalysisRegion();
        return intersectTimeRanges(analysisRegion, this.props.currentView!);
    }

    getZoomRegion() {
        if (this.state.zoomRegion.mode !== 'none') {
            const startTime = Math.min(this.state.zoomRegion.startTime, this.state.zoomRegion.endTime);
            const endTime = Math.max(this.state.zoomRegion.startTime, this.state.zoomRegion.endTime);
            return { startTime, endTime };
        } else {
            return initialZoomRegion;
        }
    }

    getDisplayZoomRegion() {
        const zoomRegion = this.getZoomRegion();
        const cappedRegion = intersectTimeRanges(zoomRegion, this.props.currentView!);
        return { mode: this.state.zoomRegion.mode, ...cappedRegion };
    }

    onMouseDown(e: React.MouseEvent) {
        const position = e.pageX;
        this.onStart(position);
        this.addDocumentEvents('mouse');
    }

    onMouseMove(e: MouseEvent) {
        const position = e.pageX;
        this.onMove(position);
    }

    onTouchStart(e: React.TouchEvent) {
        const position = e.touches[0].pageX;
        this.onStart(position);
        this.addDocumentEvents('touch');
    }

    onTouchMove(e: TouchEvent) {
        const position = e.touches[0].pageX;
        this.onMove(position);
    }

    addDocumentEvents(type: 'touch' | 'mouse') {
        if (type === 'touch') {
            window.addEventListener('touchmove', this.onTouchMove);
            window.addEventListener('touchend', this.onTouchEnd);
        } else if (type === 'mouse') {
            window.addEventListener('mousemove', this.onMouseMove);
            window.addEventListener('mouseup', this.onMouseUp);
        }
    }

    onTouchEnd() {
        window.removeEventListener('touchmove', this.onTouchMove);
        window.removeEventListener('touchend', this.onTouchEnd);
        this.onEnd();
    }

    onMouseUp() {
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
        this.onEnd();
    }

    renderTopTooltip() {

        let style: React.CSSProperties = { position: 'absolute' };
        const topChartRect = this.topChartRef.current?.getBoundingClientRect()!;
        const bottomChartHeight = this.getBottomChartHeight();

        if (!this.state.topTooltip.reverseX) {
            style = { ...style, left: (this.state.topTooltip.x! + 15) };
        } else {
            style = { ...style, right: topChartRect.width - (this.state.topTooltip.x! - 5) };
        }

        if (!this.state.topTooltip.reverseY) {
            style = { ...style, top: (this.state.topTooltip.y! + 15) };
        } else {
            style = { ...style, bottom: bottomChartHeight + topChartRect.height - (this.state.topTooltip.y! - 5) };
        }

        return (
            <>
                <div style={{ position: 'absolute', left: this.state.topTooltip.x! - 4, top: this.state.topTooltip.y! - 4, width: 6, height: 6, border: '1px solid gray', backgroundColor: 'white', borderRadius: '50%' }} />
                <div style={style}>
                    {this.state.topTooltip.display === 'trade' && this.renderTradeTooltip()}
                    {this.state.topTooltip.display === 'bid' && this.renderBidTooltip()}
                    {this.state.topTooltip.display === 'offer' && this.renderOfferTooltip()}
                </div>
            </>
        );
    }

    getBottomChartHeight() {
        const bottomChartRect = this.bottomChartRef.current?.getBoundingClientRect();

        if (bottomChartRect && this.props.tradesVisible) {
            return bottomChartRect.height;
        }

        return 0;
    }

    renderTradeTooltip() {
        if (this.props.filterMode === FilterMode.Quotes) {
            return null;
        }

        const trade = this.state.topTooltip.item as ITrade;

        return (
            <div className="trade-tooltip">
                <div className="text-white"><MarketCenter code={trade.mktcenter} /></div>
                <div><Currency amount={trade.price} decimalPlaces={4} /></div>
                <div>{trade.shares}</div>
                {trade.canceled == null || trade.canceled ? <div>Canceled</div> : null}
                <div>Sales Condition: {trade.salescondition}</div>
                <div className="text-white">{Duration.fromObject({ milliseconds: trade.timestamp }).toISOTime()}</div>
            </div>
        );
    }

    renderBidTooltip() {
        if (this.props.filterMode === FilterMode.Trades) {
            return null;
        }

        const bid = this.state.topTooltip.item as IQuoteDataPoint<IQuote>;

        return bid.item && (
            <div className="bid-tooltip">
                <div className="text-white"><MarketCenter code={bid.item.mc} /></div>
                <div><Currency amount={bid.value ?? 0} /></div>
                <div className="text-white">{Duration.fromObject({ milliseconds: bid.time }).toISOTime()}</div>
            </div>
        );
    }

    renderOfferTooltip() {
        if (this.props.filterMode === FilterMode.Trades) {
            return null;
        }

        const offer = this.state.topTooltip.item as IQuoteDataPoint<IQuote>;

        return offer.item && (
            <div className="offer-tooltip">
                <div className="text-white"><MarketCenter code={offer.item.mc} /></div>
                <div><Currency amount={offer.value ?? 0} /></div>
                <div className="text-white">{Duration.fromObject({ milliseconds: offer.time }).toISOTime()}</div>
            </div>
        );
    }

    renderBottomTooltip() {

        const bottomChartRect = this.bottomChartRef.current?.getBoundingClientRect()!;

        let style: React.CSSProperties = ({
            position: 'absolute',
            left: this.state.bottomTooltip.x!,
            bottom: bottomChartRect.height,
            transform: 'translateX(-50%)'
        });

        const summary = this.state.bottomTooltip.item as IAggregatedTrade;

        return (
            <>
                <div style={style}>
                    <div className="summary-tooltip">
                        <table>
                            <tbody>
                                <tr>
                                    <th>High:</th>
                                    <td><Currency amount={summary.high ?? 0} decimalPlaces={4} /></td>
                                </tr>
                                <tr>
                                    <th>Low:</th>
                                    <td><Currency amount={summary.low ?? 0} decimalPlaces={4} /></td>
                                </tr>
                                <tr>
                                    <th>Last:</th>
                                    <td><Currency amount={summary.last ?? 0} decimalPlaces={4} /></td>
                                </tr>
                                <tr>
                                    <th>Vol:</th>
                                    <td>{numberFormatter.format(summary.volume ?? 0)}</td>
                                </tr>
                                <tr>
                                    <th>Start:</th>
                                    <td>{Duration.fromObject({ milliseconds: summary.startTime }).toISOTime()}</td>
                                </tr>
                                <tr>
                                    <th>End:</th>
                                    <td>{Duration.fromObject({ milliseconds: summary.endTime }).toISOTime()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }

    getDisplayQuoteLines() {
        if (!this.displayQuoteLines) return [];

        return this.changeTimes;
    }

    get displayQuoteLines() {
        const tickGap = this.getTickGap();

        return tickGap === 1;
    }

    get changeTimes() {
        const { bids, offers } = this.props;

        return getChanges(bids, offers);
    }

    getTickGap() {
        const { currentView } = this.props;

        if (!currentView) return 0;

        const { endTime, startTime } = currentView;
        const duration = endTime - startTime;

        return getOptimalTickGap(duration);
    }

    async handlePrint() {
        if (canPrint(this)) {
            const { start, symbol } = this.props.currentReplay!.details;
            const { startTime, endTime } = this.props.currentView!;
            const startString = getDateTime(start, startTime).toFormat("HHmmssSSS");
            const endString = getDateTime(start, endTime).toFormat("HHmmssSSS");

            const filename = symbol
                + "_" + start.toFormat("yyyy-MM-dd")
                + "_" + startString
                + "-" + endString
                + ".png";

            const topChartWrapper = this.topChartRef.current?.getElementsByClassName("recharts-wrapper")[0];
            const bottomChartWrapper = this.bottomChartRef.current?.getElementsByClassName("recharts-wrapper")[0];

            await printCharts(
                topChartWrapper,
                bottomChartWrapper,
                filename
            );
        }

        function canPrint(context: any) {
            return context.topChartRef.current 
                && context.props.currentReplay
                && context.props.currentView;
        }
    }

    render() {
        const { mode } = this.state;
        const { canZoomOut } = this.props;

        return (
            <ZoomRegionContext.Provider value={this.getDisplayZoomRegion()}>
                <AnalysisRegionContext.Provider value={this.getDisplayAnalysisRegion()}>
                    <QuoteLineContextProvider value={this.getDisplayQuoteLines()}>
                        <div className="chart-outer-wrapper">
                            <div className="chart-wrapper">
                                <Chart
                                    mode={this.state.mode}
                                    innerRef={this.innerRef}
                                    topChartRef={this.topChartRef}
                                    bottomChartRef={this.bottomChartRef}
                                    tickGap={this.getTickGap()}
                                    showBottomChart={this.props.tradesVisible}
                                />

                                {this.state.topTooltip.display !== 'none' && this.renderTopTooltip()}
                                {this.state.bottomTooltip.display && this.renderBottomTooltip()}

                                <div className="chart-interactivity-zone"
                                    style={{ cursor: this.state.topTooltip.display === 'trade' ? 'pointer' : '' }}
                                    onMouseDown={this.onMouseDown}
                                    onTouchStart={this.onTouchStart}
                                    onMouseMove={this.onMouseMoveForTooltip}
                                    onMouseLeave={this.clearTooltips}
                                />
                            </div>
                            <div className="chart-buttons">
                                <ZoomOutButton onZoomOutClick={this.onZoomOut} canZoomOut={canZoomOut} />
                                <ZoomInButton onZoomInClick={() => this.onSetMode("zoom")} active={mode === "zoom"} />
                                <PanButton onPanClick={() => this.onSetMode("pan")} active={mode === "pan"} />
                                <AnalysisButton onAnalysisClick={() => this.onSetMode("analysis")} active={mode === "analysis"} />
                                <ScreenshotButton onScreenshotClick={this.handlePrint} />
                            </div>
                        </div>

                        <QuoteDetails
                            quotes={this.state.quoteDetails}
                            onClose={this.clearQuoteDetails}
                        />
                    </QuoteLineContextProvider>
                </AnalysisRegionContext.Provider>
            </ZoomRegionContext.Provider>
        )
    }
}

const mapState = (store: AppStore) => ({
    currentView: getCurrentView(store),
    analysisRegion: getAnalysisRegion(store),
    trades: getTradesInCurrentView(store),
    bids: getBestBidsInCurrentView(store),
    offers: getBestOffersInCurrentView(store),
    aggregatedTrades: getTradeSummaryInCurrentView(store),
    quotes: getQuotes(store),
    filterMode: getFilterMode(store),
    tradesVisible: tradesVisible(store),
    canZoomOut: getCanZoomOut(store),
    currentReplay: getOpenReplay(store)
});

const mapDispatch = ({
    setCurrentTime: (time: number) => setCurrentTime({ time }),
    zoomIn: (timeRange: TimeRange) => zoomIn(timeRange),
    zoomOut: () => zoomOut(),
    pan: (newStartTime: number) => pan({ newStartTime }),
    setAnalysisRegion: (region: TimeRange) => setAnalysisRegion({ region }),
    selectTrade: (trade: ITrade) => selectTrade(trade)
})

const connector = connect(mapState, mapDispatch)

export default connector(ChartContextProviver);
