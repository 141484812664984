import React from "react";
import { UncontrolledTooltip, CircleButton, FontAwesomeIcon } from "@nef/core";

interface ChartButtonProps {
    text: string;
    icon: string;
    disabled?: boolean;
    active?: boolean;
    onClick: () => void;
}

const ChartButton = ({ text, icon, disabled = false, active = false, onClick }: ChartButtonProps) => (
    <UncontrolledTooltip placement="left" title={<span>{text}</span>}>
        <CircleButton onClick={onClick} disabled={disabled} ghost={!active} size="sm">
            <FontAwesomeIcon iconClassName={`fa-fw ${icon}`} />
        </CircleButton>
    </UncontrolledTooltip>
)

const ZoomOutButton = ({ onZoomOutClick, canZoomOut }: { onZoomOutClick: () => void, canZoomOut: boolean }) => (
    <ChartButton onClick={onZoomOutClick} icon="fa-search-minus" text="Zoom Out" disabled={!canZoomOut} />
)

const ZoomInButton = ({ onZoomInClick, active }: { onZoomInClick: () => void, active: boolean }) => (
    <ChartButton onClick={onZoomInClick} icon="fa-search-plus" text="Zoom In" active={active} />
);

const PanButton = ({ onPanClick, active }: { onPanClick: () => void, active: boolean }) => (
    <ChartButton onClick={onPanClick} icon="fa-arrows-alt-h" text="Pan" active={active} />
)

const AnalysisButton = ({ onAnalysisClick, active }: { onAnalysisClick: () => void, active: boolean }) => (
    <ChartButton onClick={onAnalysisClick} icon="fa-chart-area" text="Analysis" active={active} />
)

const ScreenshotButton = ({ onScreenshotClick }: { onScreenshotClick: () => void }) => (
    <ChartButton onClick={onScreenshotClick} icon="fa-camera" text="Screenshot" />
)

export { ZoomInButton, ZoomOutButton, PanButton, AnalysisButton, ScreenshotButton };
