import { Section, SectionBody, SectionHeader } from "@nef/core";
import LogInForm from "../components/login-form";
import "./LogInPage.css";

const LogInPage = () => (
    <div className="login-page">
        <div className="login-page__login">
            <Section className="login">
                <SectionHeader title="Sign in to Market Replay" />
                <SectionBody>
                    <LogInForm />
                </SectionBody>
            </Section>
        </div>
    </div>
);

export default LogInPage;
