import { findMinIndex, findMaxIndex } from "./binarySearch";

// An O(log N) function for slicing a sorted array.
// Overflow allows a certain number of extra data points either side of the range to be included.
export function rangeFilter<T>(
    data : T[],
    value : (item : T) => number,
    min: number,
    max: number,
    overflow: number
    ) : T[] {

    let startIndex = findMinIndex(data, value, min);
    let endIndex = findMaxIndex(data, value, max);
    
    if (startIndex === -1 || endIndex === -1) return [];

    startIndex = Math.max(startIndex - overflow, 0);
    endIndex = Math.min(endIndex + overflow, data.length);

    return data.slice(startIndex, endIndex + 1);
}
