import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppStore } from ".";
import csv from 'csvtojson';
import { IQuote, ITrade } from "../types";
import localforage from 'localforage';
import { clearTrades, setTrades } from "./tradesSlice";
import { calculateBbo, clearQuotes, setQuotes } from "./quotesSlice";
import { FilterMode, filterMode, reset } from "./viewsSlice";
import { DateTime } from 'luxon';
import { uniqBy } from "lodash";
import { logOut } from './session';

// If the format of the data we store locally changes, bump this number to
// force the users to download new data.
const localDataVersion = "0003";
const replayListKey = `${localDataVersion}-replay-list`;

type Status = "fetching" | "success" | "error";

type ReplayId = string;

interface RequestResponse {
    id: string;
    error?: string;
}

export interface ReplayDetails {
    symbol: string;
    hasQuotes: boolean;
    hasTrades: boolean;
    start: DateTime;
    end: DateTime;
}

export interface Replay {
    id: ReplayId;
    details: ReplayDetails;
    status: Status;
    selected: boolean;
}

export interface RequestsState {
    replays: Replay[];
    openReplay: { id: ReplayId, loading: boolean } | null;
}

const initialState: RequestsState = {
    replays: [],
    openReplay: null,
};

const getReplayId = (rd: ReplayDetails) => `${rd.symbol}_${rd.start.toISO()}_${rd.end.toISO()}_${rd.hasQuotes ? 'Q' : ''}_${rd.hasTrades ? 'T' : ''}`
const fromId = (id: string) => {
    const parts = id.split('_');
    const symbol = parts[0];
    const start = DateTime.fromISO(parts[1], { zone: 'America/New_York' });
    const end = DateTime.fromISO(parts[2], { zone: 'America/New_York' });
    const hasQuotes = parts[3] === 'Q';
    const hasTrades = parts[4] === 'T';

    return ({
        symbol,
        start,
        end,
        hasQuotes,
        hasTrades
    });
}

export const addReplay = createAsyncThunk<RequestResponse, ReplayDetails, { dispatch: AppDispatch, state: AppStore }>(
    "requests/addReplay",
    async (replayDetails: ReplayDetails, thunkApi) => {

        const id = getReplayId(replayDetails);

        const replay: Replay = ({
            id: id,
            details: replayDetails,
            selected: true,
            status: "fetching"
        });

        thunkApi.dispatch(slice.actions.addReplayInternal(replay));

        const replayIds = thunkApi.getState().requests.replays.map(r => r.id);
        await localforage.setItem(replayListKey, replayIds);

        await thunkApi.dispatch(downloadReplay(replay));

        const replayAfterLoad = thunkApi.getState().requests.replays.find(r => r.id === id);
        
        if (replayAfterLoad && replayAfterLoad.status === "success") {
            await thunkApi.dispatch(openReplay(replayAfterLoad));
        }

        return {
            id: replay.id,
            error: undefined,
            data: null
        };
    }
);

const addReplayInternalReducer = (state: RequestsState, action: PayloadAction<Replay>) => {

    state.replays.forEach(r => r.selected = r.id === action.payload.id);

    if (!state.replays.find(r => r.id === action.payload.id)) {
        state.replays.push(action.payload);
    }
};

const selectReplayReducer = (state: RequestsState, action: PayloadAction<string>) => ({
    ...state,
    replays: state.replays.map(r => ({
        ...r,
        selected: action.payload === r.id
    }))
});

const toggleReplayReducer = (state: RequestsState, action: PayloadAction<ReplayId>) => ({
    ...state,
    replays: state.replays.map(r => ({
        ...r,
        selected: r.id === action.payload ? !r.selected : r.selected
    }))
});

const clearSelectedReducer = (state: RequestsState) => ({
    ...state,
    replays: state.replays.map(r => ({
        ...r,
        selected: false
    }))
})

const openReplayInternalReducer = (state: RequestsState, action: PayloadAction<{ id: ReplayId, loading: boolean }>) => ({
    ...state,
    openReplay: action.payload
});

const deleteSelectedReplaysInternalReducer = (state: RequestsState) => ({
    ...state,
    replays: state.replays.filter(r => !r.selected)
})

export const deleteSelectedReplays = createAsyncThunk<void, void, { state: AppStore, dispatch: AppDispatch }>(
    "requests/deleteSelectedReplays",
    async (x, thunkApi) => {

        const state = thunkApi.getState();
        const replay = getOpenReplay(state);

        if (replay && replay.selected) {
            thunkApi.dispatch(clearTrades());
            thunkApi.dispatch(clearQuotes());
            thunkApi.dispatch(slice.actions.closeReplay());
        }

        const keys = await localforage.keys();

        const selectedReplays = getSelectedReplays(state);
        selectedReplays.forEach(r => {
            if (r.details.hasTrades) {
                const tradesKey = `${localDataVersion}_Trades_${r.id}`;
                if (keys.includes(tradesKey))  {
                    localforage.removeItem(tradesKey);
                }
            }
            if (r.details.hasQuotes) {
                const quotesKey = `${localDataVersion}_Quotes_${r.id}`;
                if (keys.includes(quotesKey))  {
                    localforage.removeItem(quotesKey);
                }
            }
        })

        thunkApi.dispatch(slice.actions.deleteSelectedReplaysInternal());

        const replayIds = thunkApi.getState().requests.replays.map(r => r.id);
        await localforage.setItem(replayListKey, replayIds);
    }
);

const closeReplayReducer = (state: RequestsState) => ({
    ...state,
    openReplay: null
})

const setStatusReducer = (state: RequestsState, action: PayloadAction<{ replayId: ReplayId, status: Status }>) => {
    const replay = state.replays.find(r => r.id === action.payload.replayId);
    if (replay) {
        replay.status = action.payload.status;
    }
};

export const loadReplays = createAsyncThunk(
    "replays/load",
    async () => {

        console.log('Looking for replay ID list in local storage.');

        const ids = await localforage.getItem(replayListKey) as string[];

        if (!ids) {
            console.log('Replay ID list not found.');
            return [];
        }

        console.log(`Found list with ${ids.length} replay ID(s).`);

        const keys = await localforage.keys();

        const replays: ReplayDetails[] = [];

        ids.forEach(id => {
            let error = false;

            console.log(`Checking data for ${id}.`);

            const replayDetails = fromId(id);

            if (replayDetails.hasTrades) {
                const tradesKey = `${localDataVersion}_Trades_${id}`;
                if (!keys.includes(tradesKey)) {
                    console.log(`Unable to find trades for ${id}`);
                    error = true;
                }
            }

            if (replayDetails.hasQuotes) {
                const quotesKey = `${localDataVersion}_Quotes_${id}`;
                if (!keys.includes(quotesKey)) {
                    console.log(`Unable to find quotes for ${id}`);
                    error = true;
                }
            }

            if (!error) {
                replays.push(replayDetails);
            }
        })

        console.log(`Found ${replays.length} replay(s).`);

        return replays;
    }
);

const loadFulfilledReducer = (state: RequestsState, action: PayloadAction<ReplayDetails[]>) => {
    state.replays = action.payload.map((r, i) => ({ id: getReplayId(r), details: r, status: 'success', selected: i === 0 }));
    state.openReplay = null;
};

function toMilliseconds(date: DateTime) {
    return date.toMillis() - date.startOf('day').toMillis();
}

export const openReplay = createAsyncThunk<RequestResponse, Replay, { dispatch: AppDispatch }>(
    "requests/openReplay",
    async (replay: Replay, thunkApi) => {

        if (replay.status === "success") {

            console.time('Open Replay');

            thunkApi.dispatch(slice.actions.openReplayInternal({ id: replay.id, loading: true }));

            const timeRange = ({
                startTime: toMilliseconds(replay.details.start),
                endTime: toMilliseconds(replay.details.end)
            });

            console.time('Open Replay - Load Trades')
            const tradesKey = `${localDataVersion}_Trades_${replay.id}`;
            const trades = await localforage.getItem(tradesKey) as ITrade[];
            console.timeEnd('Open Replay - Load Trades')

            console.time('Open Replay - Load Quotes')
            const quotesKey = `${localDataVersion}_Quotes_${replay.id}`;
            const quotes = await localforage.getItem(quotesKey) as IQuote[];
            console.timeEnd('Open Replay - Load Quotes')

            console.time('Open Replay - Reset view')
            thunkApi.dispatch(reset(timeRange));
            console.timeEnd('Open Replay - Reset view')

            console.time('Open Replay - Reset filter mode')
            let mode: FilterMode;
            if (replay.details.hasQuotes && replay.details.hasTrades) {
                mode = FilterMode.All;
            } else if (replay.details.hasQuotes) {
                mode = FilterMode.Quotes;
            } else {
                mode = FilterMode.Trades;
            }
            thunkApi.dispatch(filterMode(mode));
            console.timeEnd('Open Replay - Reset filter mode')

            if (trades) {
                console.time('Open Replay - Set Trades')
                thunkApi.dispatch(setTrades({ trades }));
                console.timeEnd('Open Replay - Set Trades')
            }

            if (quotes) {
                console.time('Open Replay - Set Quotes')
                thunkApi.dispatch(setQuotes(quotes));
                thunkApi.dispatch(calculateBbo());
                console.timeEnd('Open Replay - Set Quotes')
            }

            console.time('Open Replay - Set Current Replay')
            thunkApi.dispatch(slice.actions.openReplayInternal({ id: replay.id, loading: false }));
            console.timeEnd('Open Replay - Set Current Replay')

            console.timeEnd('Open Replay');
        }

        return {
            id: replay.id,
            error: undefined,
            data: null
        };
    }
);

const parseBoolean = (val?: string | null) =>
    val != null ? val.toLocaleLowerCase() === "true" : false;

export const downloadReplay = createAsyncThunk<RequestResponse, Replay, { dispatch: AppDispatch }>(
    "requests/downloadReplay",
    async (replay: Replay, thunkApi) => {

        thunkApi.dispatch(slice.actions.setStatus({ replayId: replay.id, status: "fetching" }));

        const offsetMillis = replay.details.start.startOf("day").toMillis();

        /*
        The timestamps returned by the backend API are wrongly returned as if
        they were UTC timestamps. We need to adjust these by the appropritate offset
        for the time of year (4hrs for NY summertime, 5hrs for NY non-summertime).
        The replay's start date is a DateTime object that already provides this
        for us as a number of minutes.
        */
        const fixTimeZoneMs = replay.details.start.offset * 60 * 1000;

        const formatTimestamp = (item: any) =>
            DateTime.fromISO(item).toMillis() - offsetMillis - fixTimeZoneMs;

        let error = false;

        if (replay.details.hasTrades) {
            try {
                const tradesKey = `${localDataVersion}_Trades_${replay.id}`;
                let trades = await localforage.getItem(tradesKey) as ITrade[];
                if (trades) {
                    console.log('Using trades data from local storage.');
                } else {
                    console.log('Fetching trades data from remote storage.');
                    const url = `/trades?symbol=${replay.details.symbol}&startTime=${replay.details.start.toISO()}&endTime=${replay.details.end.toISO()}`;
                    const response = await fetch(url);
                    
                    if (response.status === 401) {
                        alert('You have been signed out. Please sign in and try again.');
                        thunkApi.dispatch(logOut());
                        throw new Error('Unable to fetch trades data, 401.');
                    }
                    else if (response.status !== 200) {
                        throw new Error('Unable to fetch trades data.');
                    }

                    const csvData = await response.text();

                    trades = (await csv({
                      checkType: true,
                      colParser: {
                        timestamp: formatTimestamp,
                        canceled: parseBoolean
                      },
                    }).fromString(csvData)) as ITrade[];

                    // The trade data returned has duplicate entries. We can remove these duplicates based on seqnum.
                    trades = uniqBy(trades, 'seqnum');

                    // Ensure 'time' is an available property to match other items on the chart
                    trades = trades.map(t => ({ ...t, time: t.timestamp }));

                    // The resulting array may no longer be ordered by sequence number.
                    trades.sort((a, b) => a.seqnum - b.seqnum);

                    if (trades.length > 0) {
                        // Ensure trade data is sorted.
                        trades.reduce((a, b) => {
                            if (b.timestamp < a.timestamp || b.seqnum <= a.seqnum) {
                                throw new Error(`Trade data must be sorted by time and sequence number. See ${JSON.stringify(a)} and ${JSON.stringify(b)}.`);
                            }
                            return b;
                        });
                    }

                    await localforage.setItem(tradesKey, trades);
                }
            } catch (e) {
                console.log('Error fetching trades.', e);
                error = true;
            }
        }

        if (!error && replay.details.hasQuotes) {
            try {
                const quotesKey = `${localDataVersion}_Quotes_${replay.id}`;
                let quotesData = await localforage.getItem(quotesKey) as IQuote[];
                if (quotesData) {
                    console.log('Using quotes data from local storage.');
                } else {
                    console.log('Fetching quotes data from remote storage.');
                    const url = `/quotes?symbol=${replay.details.symbol}&startTime=${replay.details.start.toISO()}&endTime=${replay.details.end.toISO()}`;
                    const response = await fetch(url);

                    if (response.status === 401) {
                        alert('You have been signed out. Please sign in and try again.');
                        thunkApi.dispatch(logOut());
                        throw new Error('Unable to fetch quotes data, 401.');
                    }
                    else if (response.status !== 200) {
                        throw new Error('Unable to fetch quotes data.');
                    }

                    const csvData = await response.text();

                    let quotes = await csv({
                        noheader: false,
                        headers: ['seqnum', 'mc', 'bidQuantity', 'offerQuantity', 'low', 'high', 'start', 'end', 'x1', 'x2', 'x3'],
                        colParser: {
                            'seqnum': 'number',
                            'mc': 'string',
                            'bidQuantity': 'number',
                            'offerQuantity': 'number',
                            'low': 'number',
                            'high': 'number',
                            'start': (item: any) => (item - offsetMillis) - fixTimeZoneMs,
                            'end': (item: any) => (item - offsetMillis) - fixTimeZoneMs,
                            'x1': 'omit',
                            'x2': 'omit',
                            'x3': 'omit'
                        }
                    })
                        .fromString(csvData) as IQuote[];

                    // Need to sort quotes.
                    // First by start time then by end time
                    quotes.sort((a, b) => {
                        if (a.start - b.start !== 0) {
                            return a.start - b.start;
                        }

                        return a.end - b.end;
                    });


                    // Ensure quote data is sorted.
                    if (quotes.length > 0) {
                        quotes.reduce((a, b) => {
                            if (b.start < a.start) {
                                throw new Error('Quote data must be sorted by start time.');
                            }
                            return b;
                        });
                    }

                    await localforage.setItem(quotesKey, quotes);
                }
            } catch (e) {
                console.log('Error fetching quotes.', e);
                error = true;
            }
        }

        if (!error) {
            thunkApi.dispatch(slice.actions.setStatus({ replayId: replay.id, status: "success" }));
        } else {
            thunkApi.dispatch(slice.actions.setStatus({ replayId: replay.id, status: "error" }));
        }

        return {
            id: replay.id,
            error: undefined,
            data: null
        };
    }
)

export const slice = createSlice({
    name: "requests",
    initialState: initialState,
    reducers: {
        addReplayInternal: addReplayInternalReducer,
        openReplayInternal: openReplayInternalReducer,
        deleteSelectedReplaysInternal: deleteSelectedReplaysInternalReducer,
        selectReplay: selectReplayReducer,
        toggleReplaySelected: toggleReplayReducer,
        clearSelected: clearSelectedReducer,
        setStatus: setStatusReducer,
        closeReplay: closeReplayReducer
    },
    extraReducers: builder => {
        builder.addCase(loadReplays.fulfilled, loadFulfilledReducer)
    }
})

export const {
    selectReplay,
    toggleReplaySelected,
    clearSelected,
    closeReplay
} = slice.actions;

export const getReplays = (state: AppStore) => state.requests.replays;
export const getSelectedReplays = (state: AppStore) => state.requests.replays.filter(r => r.selected);
export const getOpenReplay = (state: AppStore) => state.requests.openReplay ? state.requests.replays.find(r => r.id === state.requests.openReplay!.id)! : null;
export const getOpenReplayIsLoading = (state: AppStore) => state.requests.openReplay !== null && state.requests.openReplay.loading;

export default slice.reducer;
