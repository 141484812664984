import AnalysisPanel from "./AnalysisPanel";
import ChartPanel from "./ChartPanel";
import PointInTimeAnalysisPanel from "./PointInTimeAnalysisPanel";
import "./ReplayPage.css";

const ReplayPage = () => (
    <div className="replay-page">
        <div className="replay-page__left">
            <div className="replay-page__section">
                <ChartPanel />
            </div>
            <div className="replay-page__section">
                <PointInTimeAnalysisPanel />
            </div>
        </div>
        <div className="replay-page__right">
            <div className="replay-page__section">
                <AnalysisPanel />
            </div>
        </div>
    </div>
)

export default ReplayPage;