import { Section, SectionBody, SectionHeader } from "@nef/core";
import RequestForm from "../components/request-form";
import ReplayList from "../components/replay-list";
import "./ReplayListPage.css";

const ReplayListPage = () => (
    <div className="replay-list-page">
        <div className="replay-list-page__replay-list">
            <Section>
                <SectionHeader title="Replays" />
                <SectionBody>
                    <ReplayList />
                </SectionBody>
            </Section>
        </div>
        <div className="replay-list-page__new-replay">
            <Section>
                <SectionHeader title="Download Replay" />
                <SectionBody>
                    <RequestForm />
                </SectionBody>
            </Section>
        </div>
    </div>
)

export default ReplayListPage;