import React, { memo, useCallback, useMemo, useRef } from 'react';
import { CartesianGrid, Line, XAxis, YAxis, ResponsiveContainer, ComposedChart, Scatter, BarChart, Bar, Dot } from 'recharts';
import { IAggregatedTrade } from '../../types';
import { useSelector } from 'react-redux';
import { getCurrentView, getFilterMode, FilterMode } from '../../store/viewsSlice';
import { getTradesInCurrentView, getTradeSummaryInCurrentView } from '../../store/tradesSlice';
import { getBestBidsInCurrentView, getBestOffersInCurrentView } from '../../store/quotesSlice';
import CustomReferenceLine from './CustomReferenceLine';
import ZoomRegion from './ZoomRegion';
import AnalysisRegion from './AnalysisRegion';
import { Duration } from "luxon";
import QuoteChangeLines from './QuoteChangeLines';
import './chart.css';

const Chart: React.FC<any> = memo((props: any) => {
    const { tickGap } = props;

    const currentView = useSelector(getCurrentView);
    const summaryData = useSelector(getTradeSummaryInCurrentView);

    const trades = useSelector(getTradesInCurrentView);
    const bestOffers = useSelector(getBestOffersInCurrentView);
    const bestBids = useSelector(getBestBidsInCurrentView);
    const filterMode = useSelector(getFilterMode);

    const data = useMemo(() => {
        let data: any[] = [];

        if (filterMode !== FilterMode.Trades) {
            data = [
                ...data,
                ...bestBids.map(b => ({ ...b, bidVal: b.value })),
                ...bestOffers.map(o => ({ ...o, offerVal: o.value }))
            ];
        }

        if (filterMode !== FilterMode.Quotes) {
            data = [
                ...data,
                ...trades.map(t => ({ ...t, tradeVal: t.price }))
            ];
        }

        return data;
    }, [filterMode, trades, bestBids, bestOffers]);


    let ticks: number[] = [];
    for (let t = 0; t <= currentView!.endTime; t += tickGap) {
        if (t >= currentView!.startTime) {
            ticks.push(t);
        }
    }

    let y2Ticks: number[] = [];
    const maxVolume = summaryData.map(t => t.volume ?? 0).reduce((a, b) => Math.max(a, b), 0);
    if (maxVolume > 0) {
        let y2TickSize = Math.pow(10, Math.ceil(Math.log10(maxVolume / 5)));
        if (maxVolume / y2TickSize < 1) y2TickSize /= 2;
        for (let t = 0; t <= maxVolume; t += y2TickSize) {
            y2Ticks.push(t);
        }
    }

    const { onMouseDown, onMouseMove, onMouseUp } = props;

    const { innerRef } = props;
    const localRef = useRef<any>();
    const barChartRef = useRef<any>();

    const refMemo = useCallback((node) => {
        innerRef(node);
        localRef.current = node;
    }, [innerRef]);

    const xAxisTickFormatter = (t: any) => t && Duration.fromObject({ milliseconds: t }).toISOTime();
    const yAxisTickFormatter = (t: any) => t && t.toFixed(2);
    const yAxis2TickFormatter = (t: any) => t && t.toLocaleString();
    const summaryKeyFunc = (t: IAggregatedTrade) => (t.startTime + t.endTime) / 2;

    return currentView && (
        <>
        <div className="charts-container">
            <div
                className="top-chart"
                ref={props.topChartRef}
                style={{ gridRow: props.showBottomChart ? "1 / 2" : "1 / 3" }}
            >
                <ResponsiveContainer>
                    <ComposedChart
                        ref={refMemo}
                        data={data}
                        margin={{ left: -10 }}
                    >
                        <CartesianGrid
                            stroke="#E0E0E0"
                            horizontal={false}
                        />

                        <XAxis
                            type="number"
                            dataKey="time"
                            domain={[currentView.startTime, currentView.endTime]}
                            tickFormatter={xAxisTickFormatter}
                            angle={-90}
                            ticks={ticks}
                            tick={{ fontSize: 10, dx: 10, dy: 2 }}
                            tickSize={3}
                            interval={0}
                            textAnchor="start"
                            mirror
                            allowDataOverflow={true}
                        />

                        <YAxis
                            type="number"
                            domain={['dataMin-0.1', 'dataMax+0.1']}
                            tickFormatter={yAxisTickFormatter}
                            tickCount={10}
                            tick={{ fontSize: 10 }}
                            allowDataOverflow={true} />

                        <Line
                            name="best-bids"
                            dataKey="bidVal"
                            type="stepAfter"
                            dot={false}
                            stroke="#0092BC"
                            isAnimationActive={false}
                        />

                        <Line
                            name="best-offers"
                            dataKey="offerVal"
                            type="stepAfter"
                            dot={false}
                            stroke="#A6D18C"
                            isAnimationActive={false}
                        />

                        <Scatter
                            name="trades"
                            isAnimationActive={false}
                            dataKey="tradeVal"
                            fill="#CB6015"
                            shape={<Dot r={2} />}
                        />

                        <CustomReferenceLine
                            stroke="#4D4D4D"
                            strokeWidth={1} />

                        <QuoteChangeLines />

                        <AnalysisRegion
                            fill='#A6D18C'
                            fillOpacity={0.1}
                            strokeWidth={0} />

                        <ZoomRegion
                            fill='#D4D4D4'
                            fillOpacity={0.5}
                            strokeWidth={0} />

                    </ComposedChart>
                </ResponsiveContainer>
            </div>
            {
                props.showBottomChart && (
                    <div className="bottom-chart" ref={props.bottomChartRef}>
                        <ResponsiveContainer>
                            <BarChart
                                ref={barChartRef}
                                data={summaryData}
                                barCategoryGap={0}
                                onMouseDown={onMouseDown}
                                onMouseMove={onMouseMove}
                                onMouseUp={onMouseUp}
                                margin={{ left: -10, bottom: 5 }}>
                                <XAxis
                                    type="number"
                                    dataKey={summaryKeyFunc}
                                    domain={[currentView.startTime, currentView.endTime]}
                                    tick={false}
                                    mirror
                                    allowDataOverflow={true} />
                                <YAxis
                                    type="number"
                                    dataKey="volume"
                                    tickFormatter={yAxis2TickFormatter}
                                    ticks={y2Ticks}
                                    interval={0}
                                    tick={{ fontSize: 9 }}
                                    allowDataOverflow={true} />
                                <Bar
                                    dataKey="volume"
                                    fill="#CB6015"
                                    isAnimationActive={false} />
                                <CustomReferenceLine
                                    stroke="#4D4D4D"
                                    strokeWidth={1} />
                                <AnalysisRegion
                                    fill='#A6D18C'
                                    fillOpacity={0.1}
                                    strokeWidth={0} />
                                <ZoomRegion
                                    fill='#D4D4D4'
                                    fillOpacity={0.5}
                                    strokeWidth={0} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                )
            }
        </div>
        </>
    );
});

export default Chart;
