const possibleTickGaps = [
    60 * 60 * 1000, // 1 hour
    30 * 60 * 1000, // 30 mins
    10 * 60 * 1000, // 10 mins
    5 * 60 * 1000, // 5 mins
    2 * 60 * 1000, // 2 mins
    60 * 1000, // 1 minute
    30 * 1000, // 30 seconds
    10 * 1000, // 10 seconds
    5 * 1000, // 5 seconds
    2 * 1000, // 2 seconds
    1000, // 1 second
    500, // 500 ms
    200, // 200ms
    100, // 100 ms
    50, // 50 ms,
    25, // 25 ms,
    10, // 10 ms,
    5, // 5 ms,
    2, // 2 ms
    1, // 1 ms
];

const getOptimalTickGap = (duration: number) => {
    if (duration === 10) return 1;

    const bestMatch = possibleTickGaps.find(size => duration / size >= 5)

    return bestMatch ?? 1;
}

export { possibleTickGaps, getOptimalTickGap };
