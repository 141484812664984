import { Button } from '@nef/core';
import useExportAnalysisRegionQuoteSummary from '../../utils/useExportAnalysisRegionQuoteSummary';

const ExportAnalysisRegionQuoteSummary = () => {

    const [enabled, exportAnalysisRegionQuoteSummary] = useExportAnalysisRegionQuoteSummary();

    return (
        <Button
            onClick={exportAnalysisRegionQuoteSummary}
            color="secondary"
            outline size="sm"
            disabled={!enabled}>
            Export
        </Button>
    );
}

export default ExportAnalysisRegionQuoteSummary