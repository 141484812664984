import { UncontrolledTooltip } from '@nef/core';
import React from 'react';
import marketCenters from "../../mcCodes.json";

export interface MarketCenterProps {
    code: string
}

const MarketCenter = ({code} : MarketCenterProps) => {
    
    const marketCenter = marketCenters.find(mc => mc.code === code);

    if (!marketCenter) { return <span>-</span>; }

    return (
        <UncontrolledTooltip placement="top" title={<span>{marketCenter.name}</span>}>
            <span>{marketCenter.mic}</span>
        </UncontrolledTooltip> 
    );
}

export default MarketCenter;