/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { DateTime, Duration } from "luxon";
import { parseDate, parseTime } from "./utils";
import { MAX_REPLAY_SIZE_MINUTES } from "../../utils/appSettings";
import { ReplayDetails } from "../../store/requestsSlice";

const invalidTimeMessage =
  "${path} must be a specified as hours and minutes, e.g. 14:30.";

export enum RequestType {
  All = "All",
  Quotes = "Quotes",
  Trades = "Trades",
}

const openTime = Duration.fromObject({ hours: 4, minutes: 0 });
const closeTime = Duration.fromObject({ hours: 20, minutes: 0 });

export const ReplayRequestSchema = yup.object({
  symbol: yup
    .string()
    .label("Symbol")
    .required()
    .matches(/[A-Za-z]+\s*/, "${path} must be a valid symbol."),
  date: yup
    .string()
    .label("Date")
    .required()
    .test(
      "is-valid-date",
      "Date is not valid.",
      (value) => parseDate(value).isValid
    )
    .test(
      "is-not-future",
      "Data is not available for future dates.",
      (value) => parseDate(value) <= DateTime.now()
    )
    .test(
      "is-in-range",
      "Data is not available for dates before January 2, 2014.",
      (value) => {
        const minDate = DateTime.fromObject({ year: 2014, month: 1, day: 2 });

        return parseDate(value) > minDate;
      }
    ),
  start: yup
    .string()
    .label("Start Time")
    .required()
    .test(
      "is-valid-time",
      invalidTimeMessage,
      (value) => parseTime(value).isValid
    )
    .test(
      "is-after-open",
      `Start time must be at least ${DateTime.now()
        .startOf("day")
        .plus(openTime)
        .toLocaleString(DateTime.TIME_SIMPLE)}.`,
      (value: string | undefined, context: any) => {
        const start = parseTime(value);
        return start >= openTime;
      }
    )
    .test(
      "is-before-end",
      "Start time must be before end time.",
      (value: string | undefined, context: any) => {
        const end = parseTime(context.parent.end);
        const start = parseTime(value);
        return end > start;
      }
    ),
  end: yup
    .string()
    .label("End Time")
    .required()
    .test(
      "is-valid-time",
      invalidTimeMessage,
      (value) => parseTime(value).isValid
    )
    .test(
      "is-before-close",
      `End time must be at most ${DateTime.now()
        .startOf("day")
        .plus(closeTime)
        .toLocaleString(DateTime.TIME_SIMPLE)}.`,
      (value: string | undefined, context: any) => {
        const end = parseTime(value);
        return end <= closeTime;
      }
    )
    .test(
      "is-after-start",
      "End time must be after start time.",
      (value: string | undefined, context: any) => {
        const start = parseTime(context.parent.start);
        const end = parseTime(value);
        return end > start;
      }
    )
    .test(
      "is-greater-than-two-hour-window",
      `The selected time window cannot exceed ${MAX_REPLAY_SIZE_MINUTES} minutes.`,
      (value: string | undefined, context: any) => {
        const start = parseTime(context.parent.start);
        const end = parseTime(value);

        const startDt = DateTime.now().plus(start);
        const endDt = DateTime.now().plus(end);

        const totalDuration = endDt.diff(startDt).as("minutes");

        return totalDuration <= MAX_REPLAY_SIZE_MINUTES;
      }
    )
    .when("date", {
      is: (value: string | undefined) => parseDate(value) <= DateTime.now(),
      then: yup
        .string()
        .required()
        .test(
          "is-before-max-time",
          "Data within the last 15 minutes is not available.",
          (value: string | undefined, context: any) => {
            const end = parseTime(value);

            let absoluteEnd;
            try {
              absoluteEnd = DateTime.fromISO(context.parent.date, {
                zone: "America/New_York",
              }).plus(end);
            } catch {
              // If we encounter an error here, we can assume that one of the values was invalid.
              return true;
            }
            const fifteenMinutes = Duration.fromObject({ minute: 15 });
            const maxAbsoluteEnd = DateTime.now().minus(fifteenMinutes);

            return absoluteEnd <= maxAbsoluteEnd;
          }
        ),
    }),
  requestType: yup.string().oneOf(Object.values(RequestType)).required(),
});

export function getReplayDetails(form: ReplayRequestForm): ReplayDetails {
  const { date, start, end } = form;

  const d = parseDate(date);

  return {
    symbol: form.symbol.toUpperCase(),
    hasQuotes: form.requestType === "All" || form.requestType === "Quotes",
    hasTrades: form.requestType === "All" || form.requestType === "Trades",
    start: d.plus(parseTime(start)),
    end: d.plus(parseTime(end)),
  };
}

export type ReplayRequestForm = yup.InferType<typeof ReplayRequestSchema>;
