import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppStore } from ".";
import { getPreviousQuoteInView, getNextQuoteInView } from "./quotesSlice";
import { getPreviousTradeInView, getNextTradeInView } from "./tradesSlice";
import { backwards, forwards, setCurrentTime } from "./viewsSlice";

export type PlaybackMode = "BACKWARDS" | "PAUSED" | "FORWARDS";
export type PlaybackSpeed = 1|2|3|4|5|6|7|8|9|10

let intervalID = 0;

export interface PlaybackState {
    mode: PlaybackMode,
    speed: PlaybackSpeed
}

const initialState: PlaybackState = {
    mode: 'PAUSED',
    speed: 1
};

const setModeInternalReducer = (state: PlaybackState, action: PayloadAction<PlaybackMode>) => { state.mode = action.payload };
const setSpeedInternalReducer = (state: PlaybackState, action: PayloadAction<PlaybackSpeed>) => { state.speed = action.payload };

export const setMode = (mode: PlaybackMode) => {
    return (dispatch: AppDispatch, getState: () => AppStore) => {
        const state = getState();
        dispatch(setModeInternal(mode));
        update(mode, state.playback.speed, dispatch);
    }
}

export const setSpeed = (speed: PlaybackSpeed) => {
    return (dispatch: AppDispatch, getState: () => AppStore) => {
        const state = getState();
        dispatch(setSpeedInternal(speed));
        update(state.playback.mode, speed, dispatch);
    }
}

function update(newMode : PlaybackMode, newSpeed : PlaybackSpeed, dispatch : AppDispatch) {
    if (intervalID !== 0) {
        clearInterval(intervalID);
    }

    const interval = 1000 / newSpeed;

    switch (newMode) {
        case 'PAUSED':
            intervalID = 0;
            break;

        case 'FORWARDS':
            intervalID = Number(setInterval(() => moveForwards(newSpeed, dispatch), interval));
            break;

        case 'BACKWARDS':
            intervalID = Number(setInterval(() => moveBackwards(newSpeed, dispatch), interval));
            break;
    }
}

function moveForwards(time: number, dispatch : AppDispatch) {
    dispatch(forwards(time));
}

function moveBackwards(time: number, dispatch : AppDispatch) {
    dispatch(backwards(time));
}

export const slice = createSlice({
    name: "playback",
    initialState: initialState,
    reducers: {
        setModeInternal: setModeInternalReducer,
        setSpeedInternal: setSpeedInternalReducer
    }
})


type StepDirection = "next" | "previous";
export const step = (direction: StepDirection) => {
    return (dispatch: AppDispatch, getState: () => AppStore): void => {
        const state = getState();

        if (direction === "previous") {
            const previousQuote = getPreviousQuoteInView(state);
            const previousTrade = getPreviousTradeInView(state);

            if (!previousQuote && !previousTrade) {
                return;
            } else if (!previousQuote && previousTrade) {
                dispatch(setCurrentTime({ time: previousTrade!.timestamp }))
            } else if (!previousTrade && previousQuote) {
                dispatch(setCurrentTime({ time: previousQuote!.start }))
            } else {
                dispatch(setCurrentTime({ time: Math.max(previousTrade!.timestamp, previousQuote!.start )}))
            }
        } else {
            const nextQuote = getNextQuoteInView(state);
            const nextTrade = getNextTradeInView(state);

            if (!nextQuote && !nextTrade) {
                return;
            } else if (!nextQuote && nextTrade) {
                dispatch(setCurrentTime({ time: nextTrade!.timestamp }))
            } else if (!nextTrade && nextQuote) {
                dispatch(setCurrentTime({ time: nextQuote!.start }))
            } else {
                dispatch(setCurrentTime({ time: Math.min(nextTrade!.timestamp, nextQuote!.start )}))
            }
        }
    }
}

const { setModeInternal, setSpeedInternal } = slice.actions;

export const getMode = (state : AppStore) => state.playback.mode;
export const getSpeed = (state : AppStore) => state.playback.speed;

export default slice.reducer;
